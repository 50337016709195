import React, { useEffect, useState } from 'react';
import axios from 'axios';
import styled from '@emotion/styled';
import { useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import ToggleSwitch from '../../components/ToggleSwitch';
import { usePreferences } from '../../contexts/Preferences';
import { useAuth } from '../../contexts/AuthContext';
import RunTypes from './runTypes';
import TrainingHub from './TrainingHub';

const Container = styled.div`
  max-width: 900px;
  margin: 0 auto;
  padding: 0 16px;
`;

interface BoxProps {
  mt?: number;
  mb?: number;
  p?: number;
  display?: string;
  flexDirection?: string;
  gap?: number;
}

const Box = styled.div<BoxProps>`
  margin-top: ${props => props.mt || 0}px;
  margin-bottom: ${props => props.mb || 0}px;
  padding: ${props => props.p || 0}px;
  display: ${props => props.display || 'block'};
  flex-direction: ${props => props.flexDirection || 'row'};
  gap: ${props => props.gap || 0}px;
`;

const Typography = styled.h1`
  text-align: center;
  margin-bottom: 16px;
`;

const FormContainer = styled.form`
  background: white;
  padding: 24px;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
`;

const Label = styled.label`
  font-weight: 500;
  margin-bottom: 8px;
  color: #333;
`;

const StyledSelect = styled.select`
  width: 100%;
  padding: 12px;
  border: 1px solid #ddd;
  border-radius: 6px;
  font-size: 16px;
  background-color: #f8f9fa;
  transition: border-color 0.2s;

  &:focus {
    border-color: #007bff;
    outline: none;
  }
`;

const TimeInputContainer = styled.div`
  display: flex;
  gap: 8px;
  width: 100%;
`;

const TimeInput = styled.input`
  width: 70px;
  padding: 12px;
  border: 1px solid #ddd;
  border-radius: 6px;
  font-size: 16px;
  background-color: #f8f9fa;
  text-align: center;
  transition: border-color 0.2s;

  &:focus {
    border-color: #007bff;
    outline: none;
  }

  /* Hide spinner buttons for number inputs */
  &::-webkit-inner-spin-button,
  &::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
`;

const TimeSeparator = styled.span`
  display: flex;
  align-items: center;
  font-size: 20px;
  color: #333;
`;

const StyledButton = styled.button`
  width: 100%;
  padding: 14px 20px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 6px;
  font-size: 16px;
  font-weight: 500;
  cursor: pointer;
  transition: background-color 0.2s;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;

  &:hover {
    background-color: #0056b3;
  }

  &:active {
    transform: translateY(1px);
  }

 &:disabled {
    background-color: #ccc;
    cursor: not-allowed;
    opacity: 0.7;
    transform: none;
    
    &:hover {
      background-color: #ccc;
    }
  }
`;

const CheckboxContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(140px, 1fr));
  gap: 12px;
  margin-bottom: 20px;
`;

const CheckboxLabel = styled.label`
  display: flex;
  align-items: center;
  gap: 8px;
  cursor: pointer;
  padding: 8px;
  border-radius: 4px;
  transition: background-color 0.2s;

  &:hover {
    background-color: #f0f0f0;
  }

  input {
    width: 18px;
    height: 18px;
  }
`;

const Spinner = styled.div`
  width: 20px;
  height: 20px;
  border: 3px solid rgba(255, 255, 255, 0.3);
  border-radius: 50%;
  border-top-color: white;
  animation: spin 1s linear infinite;

  @keyframes spin {
    to {
      transform: rotate(360deg);
    }
  }
`;

const AITrainingPlanGenerator = () => {
  const { user: authUser } = useAuth();
  const { preferences, updatePreferences, fetchPreferences } = usePreferences();
  const navigate = useNavigate();
  const [goal, setGoal] = useState('');
  const [duration, setDuration] = useState('');
  const [targetTime, setTargetTime] = useState('');
  const [trainingDays, setTrainingDays] = useState<string[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isKm, setIsKm] = useState(preferences?.isKm ?? true);
  const [hours, setHours] = useState('');
  const [minutes, setMinutes] = useState('');
  const [seconds, setSeconds] = useState('');
  

  useEffect(() => {
    if (authUser?.username) {
      fetchPreferences(authUser.username);
    }
  }, [authUser, fetchPreferences]);

  useEffect(() => {
    setIsKm(preferences?.isKm ?? true);
  }, [preferences]);

  useEffect(() => {
    if (hours || minutes || seconds) {
      const formattedHours = hours.padStart(2, '0');
      const formattedMinutes = minutes.padStart(2, '0');
      const formattedSeconds = seconds.padStart(2, '0');
      setTargetTime(`${formattedHours}:${formattedMinutes}:${formattedSeconds}`);
    }
  }, [hours, minutes, seconds]);

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setIsLoading(true);
    
    try {
      const trainingPaceResponse = await axios.get(`${process.env.REACT_APP_TRAINING_PLANS_API_BASE_URL}/generate-training-paces`, {
        params: {
          goal,
          duration,
          targetTime,
          isKm,
        },
      });

      console.log('trainingPaceResponse data', trainingPaceResponse.data);
      
      navigate('/view-training-plan', {
        state: {
          goal,
          duration,
          targetTime,
          trainingDays,
          isKm,
          trainingPaces: trainingPaceResponse.data,
        }
      });
    } catch (error) {
      console.error('Error generating training paces:', error);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      <Helmet>
        <title>AI Running Training Plan Generator | Personalized Training Plans</title>
        <meta name="description" content="Create your personalized AI running training plan. Get a custom training schedule tailored to your race goals, fitness level, and availability. Free AI-powered running plans." />
        <meta name="keywords" content="ai running training plan, running plan generator, personalized training plan, ai running coach" />
        <meta property="og:title" content="AI Running Training Plan Generator" />
        <meta property="og:description" content="Create your personalized AI running training plan. Get a custom training schedule tailored to your race goals." />
      </Helmet>
      <Container>
        <Box mt={32} mb={32}>
          <Typography>Generate AI Training Plan</Typography>
          <FormContainer onSubmit={handleSubmit}>
            <Box display="flex" flexDirection="column" gap={16}>
              <Label htmlFor="goal">Event</Label>
              <StyledSelect id="goal" value={goal} onChange={(e) => setGoal(e.target.value)} required>
                <option value="">Select Event</option>
                <option value="5k">5K</option>
                <option value="10k">10K</option>
                <option value="half marathon">Half Marathon</option>
                <option value="marathon">Marathon</option>
              </StyledSelect>
              <Label htmlFor="duration">Duration</Label>
              <StyledSelect id="duration" value={duration} onChange={(e) => setDuration(e.target.value)} required>
                <option value="">Select Duration</option>
                <option value="4 weeks">4 weeks</option>
                <option value="8 weeks">8 weeks</option>
                <option value="12 weeks">12 weeks</option>
                <option value="16 weeks">16 weeks</option>
              </StyledSelect>
              <Label htmlFor="targetTime">Target Time</Label>
              <TimeInputContainer>
                <TimeInput
                  type="number"
                  min="0"
                  max="99"
                  placeholder="HH"
                  value={hours}
                  onChange={(e) => {
                    const value = e.target.value;
                    if (value === '' || (parseInt(value) >= 0 && parseInt(value) <= 99)) {
                      setHours(value);
                    }
                  }}
                  required
                />
                <TimeSeparator>:</TimeSeparator>
                <TimeInput
                  type="number"
                  min="0"
                  max="59"
                  placeholder="MM"
                  value={minutes}
                  onChange={(e) => {
                    const value = e.target.value;
                    if (value === '' || (parseInt(value) >= 0 && parseInt(value) <= 59)) {
                      setMinutes(value);
                    }
                  }}
                  required
                />
                <TimeSeparator>:</TimeSeparator>
                <TimeInput
                  type="number"
                  min="0"
                  max="59"
                  placeholder="SS"
                  value={seconds}
                  onChange={(e) => {
                    const value = e.target.value;
                    if (value === '' || (parseInt(value) >= 0 && parseInt(value) <= 59)) {
                      setSeconds(value);
                    }
                  }}
                  required
                />
              </TimeInputContainer>
              <Label>Training Days</Label>
              <CheckboxContainer>
                {['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'].map((day) => (
                  <CheckboxLabel key={day}>
                    <input
                      type="checkbox"
                      checked={trainingDays.includes(day)}
                      onChange={(e) => {
                        if (e.target.checked) {
                          setTrainingDays([...trainingDays, day]);
                        } else {
                          setTrainingDays(trainingDays.filter(d => d !== day));
                        }
                      }}
                    />
                    {day}
                  </CheckboxLabel>
                ))}
              </CheckboxContainer>
              <ToggleSwitch
                id="isKm"
                leftLabel="Miles"
                rightLabel="Kilometers"
                isChecked={isKm}
                onToggle={() => setIsKm(!isKm)}
                disabled={isLoading}
              />
              <StyledButton 
                type="submit" 
                disabled={isLoading || !authUser}
                title={!authUser ? "Please sign in to generate a plan" : ""}
              >
                {isLoading ? (
                  <>
                    <Spinner /> Generating...
                  </>
                ) : !authUser ? (
                  'Sign in to Generate Plan'
                ) : (
                  'Generate Plan'
                )}
              </StyledButton>
            </Box>
          </FormContainer>
        </Box>
      </Container>
    </>
  );
};

export default AITrainingPlanGenerator;
