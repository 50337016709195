import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate, Link, useParams } from 'react-router-dom';
import styled from '@emotion/styled';
import axios from 'axios';
import { useAuth } from '../../contexts/AuthContext';
import { FaComments } from 'react-icons/fa';

const Container = styled.div`
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
`;

const Table = styled.table`
  width: 100%;
  border-collapse: collapse;
  margin-bottom: 20px;
`;

const Th = styled.th`
  background-color: #f5f5f5;
  padding: 10px;
  text-align: center;
  border: 1px solid #e0e0e0;
  font-weight: bold;
`;

const Td = styled.td`
  padding: 10px;
  border: 1px solid #e0e0e0;
  vertical-align: top;
`;

const WeekHeader = styled.th`
  background-color: #e9e9e9;
  padding: 10px;
  text-align: center;
  border: 1px solid #e0e0e0;
  font-weight: bold;
  width: 100px;
`;

const ClickableTd = styled(Link)<{ $status?: string }>`
  padding: 10px;
  border: 1px solid #e0e0e0;
  vertical-align: top;
  text-decoration: none;
  color: black;
  display: table-cell;
  cursor: pointer;
  
  ${props => {
    if (props.$status === "completed") {
      return `
        background-color: #e6ffe6;
        &:hover {
          background-color: #d6ffd6;
        }
      `;
    }
    if (props.$status === "skipped") {
      return `
        background-color: #fff3cd;
        &:hover {
          background-color: #ffe7b3;
        }
      `;
    }
  }}
`;

const WorkoutText = styled.div<{ $workoutType?: string }>`
  font-weight: bold;
  color: black;
`;

const StyledButton = styled.button`
  padding: 12px 24px;
  background-color: #0066cc;
  color: white;
  border: none;
  border-radius: 4px;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.2s ease;

  &:hover {
    background-color: #0052a3;
  }

  &:active {
    background-color: #004080;
  }
`;

const LoadingSpinner = styled.div`
  width: 40px;
  height: 40px;
  border: 3px solid #f3f3f3;
  border-top: 3px solid #1976d2;
  border-radius: 50%;
  animation: spin 1s linear infinite;

  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }
`;

const LoadingContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 200px;
  gap: 16px;
`;

const ButtonContainer = styled.div`
  display: flex;
  gap: 16px;
  margin-top: 20px;
`;

const ChatButton = styled(StyledButton)`
  margin-left: 16px;
  background-color: #28a745;

  &:hover {
    background-color: #218838;
  }

  &:active {
    background-color: #1e7e34;
  }
`;

const ChatWindow = styled.div`
  position: fixed;
  bottom: 20px;
  right: 20px;
  width: 300px;
  height: 400px;
  background-color: white;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0,0,0,0.1);
  display: flex;
  flex-direction: column;
  z-index: 1000;
`;

const ChatHeader = styled.div`
  padding: 10px;
  background-color: #28a745;
  color: white;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const ChatBody = styled.div`
  flex-grow: 1;
  padding: 10px;
  overflow-y: auto;
`;

const ChatInput = styled.div`
  padding: 10px;
  border-top: 1px solid #e0e0e0;
  display: flex;
  gap: 8px;
`;

const MessageInput = styled.input`
  flex-grow: 1;
  padding: 8px;
  border: 1px solid #e0e0e0;
  border-radius: 4px;
`;

const SendButton = styled.button`
  padding: 8px 16px;
  background-color: #28a745;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;

  &:hover {
    background-color: #218838;
  }
`;

const CloseButton = styled.button`
  background: none;
  border: none;
  color: white;
  cursor: pointer;
  font-size: 18px;
`;

const Message = styled.div<{ $isUser?: boolean }>`
  margin: 8px 0;
  padding: 8px 12px;
  border-radius: 8px;
  max-width: 80%;
  ${props => props.$isUser ? `
    background-color: #28a745;
    color: white;
    align-self: flex-end;
  ` : `
    background-color: #f0f0f0;
    align-self: flex-start;
  `}
`;

const MessagesContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

const TypingIndicator = styled.div`
  display: flex;
  gap: 4px;
  padding: 8px 12px;
  background-color: #f0f0f0;
  border-radius: 8px;
  align-self: flex-start;
  
  span {
    width: 8px;
    height: 8px;
    background: #666;
    border-radius: 50%;
    animation: bounce 1.4s infinite ease-in-out;
    
    &:nth-of-type(1) { animation-delay: -0.32s; }
    &:nth-of-type(2) { animation-delay: -0.16s; }
  }

  @keyframes bounce {
    0%, 80%, 100% { transform: translateY(0); }
    40% { transform: translateY(-8px); }
  }
`;

const FloatingChatButton = styled.button`
  position: fixed;
  bottom: 20px;
  right: 20px;
  width: 60px;
  height: 60px;
  border-radius: 50%;
  background-color: #28a745;
  color: white;
  border: none;
  cursor: pointer;
  box-shadow: 0 2px 10px rgba(0,0,0,0.2);
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 24px;
  transition: transform 0.2s;
  z-index: 999;

  &:hover {
    transform: scale(1.1);
    background-color: #218838;
  }

  &:active {
    transform: scale(0.95);
    background-color: #1e7e34;
  }
`;

interface TrainingDay {
  day: string;
  workout: string;
  distance: string;
  pace: string;
  notes: string;
  estimatedTime: string;
  status?: string;
}

interface TrainingWeek {
  week: string;
  days: TrainingDay[];
}

interface UpdatedTrainingDay {
  week: string;
  day: string;
  workout: string;
}

const ViewWholeTrainingPlan: React.FC = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { planId } = useParams<{ planId: string }>();
  // const [trainingPlanId, setTrainingPlanId] = useState<string | null>(planId || null);
  const [username, setUsername] = useState<string | null>(null);
  const [trainingPlan, setTrainingPlan] = useState<TrainingWeek[]>(location.state?.trainingPlan || []);
  const [updatedTrainingPlan, setUpdatedTrainingPlan] = useState<UpdatedTrainingDay[]>([]);
  const [trainingPlanName, setTrainingPlanName] = useState<string | null>(null);
  const [trainingObject, setTrainingObject] = useState<any>(null);
  const [isKm, setIsKm] = useState<boolean>(true);
  const { user, isTrainingPremium } = useAuth();
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [trainingPaces, setTrainingPaces] = useState<any>(null);
  const [isChatOpen, setIsChatOpen] = useState(false);
  const [messages, setMessages] = useState<Array<{ text: string; isUser: boolean }>>([]);
  const [newMessage, setNewMessage] = useState('');
  const [isWaitingForResponse, setIsWaitingForResponse] = useState(false);
  const [numberOfWeeks, setNumberOfWeeks] = useState<number>(13);
  const [updatingPlan, setUpdatingPlan] = useState<boolean>(false);
  const [trainingPlanMetadata, setTrainingPlanMetadata] = useState<any>(null);
  const [sendCoachDescribeUpdatedTrainingPlan, setSendCoachDescribeUpdatedTrainingPlan] = useState<boolean>(false);
  const [awaitingUpdatedPlanAcceptance, setAwaitingUpdatedPlanAcceptance] = useState<boolean>(false);

  useEffect(() => {
    if (user) {
      setUsername(user.username);
    }
  }, [user]);

  useEffect(() => {
    if (username && planId) {
      fetchTrainingPlan();
      fetchTrainingPlanMetadata();
    }
  }, [username]);

  const fetchTrainingPlan = async () => {
    try {
      setIsLoading(true);
      const response = await axios.get(`${process.env.REACT_APP_TRAINING_PLANS_API_BASE_URL}/get-training-plan`, {
        params: {
          planId,
          userId: username,
        }
      });
      console.log('response.data', response.data);
      console.log('response.data.paces', response.data.paces);
      setTrainingPaces(response.data.paces);
      
      const transformedPlan = Object.entries(response.data.weeks).map(([weekNum, weekData]: [string, any]) => ({
        week: weekNum,
        days: Object.entries(weekData).map(([day, dayData]: [string, any]) => ({
          day,
          workout: dayData.workout,
          distance: dayData.workout.split(' - ')[1] || '',
          pace: '',
          notes: '',
          estimatedTime: '',
          status: dayData.status
        }))
      }));

      setTrainingObject(response.data);
      setNumberOfWeeks(Object.keys(response.data.weeks).length);
      console.log('transformedPlan', transformedPlan);
      setTrainingPlan(transformedPlan);
      setTrainingPlanName(response.data.planName);
      setIsKm(response.data.isKm ?? true);
      console.log('Transformed plan:', transformedPlan);
      console.log('Raw response data:', response.data);
    } finally {
      setIsLoading(false);
    }
  }

  const fetchTrainingPlanMetadata = async () => {
    const response = await axios.get(`${process.env.REACT_APP_TRAINING_PLANS_API_BASE_URL}/get-training-plan-metadata`, {
      params: {
        planId,
        userId: username,
      }
    });
    console.log('fetchTrainingPlanMetadata', response.data);
    setTrainingPlanMetadata(response.data);
  }

  const daysOfWeek = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'];

  console.log('trainingObject', trainingObject);

  // const numberOfWeeks = 16 || trainingObject.weeks; // should be parseInt(duration)

  const getWorkoutForDay = (day: string, week: number): string => {
    const trainingDay = updatedTrainingPlan.find(td => td.day === day && Number(td.week) === week);
    return trainingDay ? trainingDay.workout : 'Rest';
  };

  console.log('numberOfWeeks', numberOfWeeks);

  const calculateEstimatedTime = (distance: string, pace: string, notes: string): string => {
    if (!distance || !pace) {
      return '';
    }

    const distanceNum = parseFloat(distance);
    const [minutes, seconds] = pace.split(':').map(Number);
    const paceInMinutes = minutes + seconds / 60;
    
    if (isNaN(distanceNum) || isNaN(paceInMinutes)) {
      return notes ? notes.replace(/^"|"$/g, '') : '';
    }

    const totalMinutes = distanceNum * paceInMinutes;
    const hours = Math.floor(totalMinutes / 60);
    const remainingMinutes = Math.round(totalMinutes % 60);

    return hours > 0 
      ? `${hours}h ${remainingMinutes} minutes`
      : `${remainingMinutes} minutes`;
  };

  const processedTrainingPlan = trainingPlan ? trainingPlan.map(week => ({
    ...week,
    days: week.days.map(day => ({
      ...day,
      estimatedTime: calculateEstimatedTime(day.distance, day.pace, day.notes)
    }))
  })) : [];

  console.log('Processed Training Plan:', processedTrainingPlan);

  const handleChatWithCoach = () => {
    if (isTrainingPremium) {
      setIsChatOpen(!isChatOpen);
    } else {
      navigate('/training-premium');
    }
  };

  const handleAcceptUpdatedTrainingPlan = async () => {
    if (newMessage.trim() === 'yes') {
      try {
        setMessages(prev => [...prev, { text: newMessage, isUser: true }]);
        setNewMessage('');
        setAwaitingUpdatedPlanAcceptance(false);
        console.log('handleAcceptUpdatedTrainingPlan try')
        
        // Update the training plan in the database
        await axios.put(`${process.env.REACT_APP_TRAINING_PLANS_API_BASE_URL}/update-training-plan`, {
          planId,
          userId: username,
          updatedPlan: updatedTrainingPlan,
        });

        setMessages(prev => [...prev, { 
          text: 'Your training plan has been successfully updated!', 
          isUser: false 
        }]);
        
        // Refresh the training plan display
        await fetchTrainingPlan();
      } catch (error) {
        console.error('Error updating training plan:', error);
        setMessages(prev => [...prev, { 
          text: 'Sorry, there was an error updating your training plan. Please try again.', 
          isUser: false 
        }]);
      }
      setNewMessage('');
      return;
    }
    else if (newMessage.trim() === 'no') {
      setMessages(prev => [...prev, { text: newMessage, isUser: true }]);
      setAwaitingUpdatedPlanAcceptance(false);
      setMessages(prev => [...prev, { text: 'Training plan not updated.', isUser: false }]);
      setNewMessage('');
      return;
    }
  }

  const handleCoachDescribeUpdatedTrainingPlan = async () => {
    console.log('handleCoachDescribeUpdatedTrainingPlan updatedTrainingPlan', updatedTrainingPlan);
    const updatedTrainingPlanMessage = `This is my updated training plan: ${JSON.stringify(updatedTrainingPlan, null, 2)}. Please describe the changes you made to the training plan to me in detail.`;
    if (updatedTrainingPlanMessage && sendCoachDescribeUpdatedTrainingPlan) {
      setIsWaitingForResponse(true);
      const response = await axios.post(`${process.env.REACT_APP_CHAT_WITH_COACH_API_BASE_URL}/send-message`, {
        message: updatedTrainingPlanMessage,
        trainingPlan,
        trainingPaces
      });
      console.log('handleCoachDescribeUpdatedTrainingPlan response.data.message', response.data.message);
      setIsWaitingForResponse(false);
      setMessages(prev => [...prev, { text: response.data.message, isUser: false }]);
      setMessages(prev => [...prev, { text: 'Would you like to accept your updated training plan?', isUser: false }]);
      setAwaitingUpdatedPlanAcceptance(true);
    }
  }

  useEffect(() => {
    handleCoachDescribeUpdatedTrainingPlan();
  }, [sendCoachDescribeUpdatedTrainingPlan]);

  const handleSendMessage = async () => {
    console.log('current logging handleSendMessage awaitingUpdatedPlanAcceptance', awaitingUpdatedPlanAcceptance);
    if (awaitingUpdatedPlanAcceptance) {
      handleAcceptUpdatedTrainingPlan();
      return;
    }
    if (newMessage.trim()) {
      try {
        // Add user message immediately
        setMessages(prev => [...prev, { text: newMessage, isUser: true }]);
        setNewMessage('');
        setIsWaitingForResponse(true);

        console.log('logging trainingPlan', trainingPlan);

        // Wait for coach's response
        const response = await axios.post(`${process.env.REACT_APP_CHAT_WITH_COACH_API_BASE_URL}/send-message`, {
          message: newMessage,
          trainingPlan,
          trainingPaces
        });

        if (response.data.message) {
          // Add coach's response once received
          setMessages(prev => [...prev, { 
            text: response.data.message,
            isUser: false 
          }]);
          if (response.data.planNeedsAdjustment) {
            console.log('planNeedsAdjustment', response.data.planNeedsAdjustment);
            setUpdatingPlan(response.data.planNeedsAdjustment);
          }
          else {
            console.log('planNeedsAdjustment else', response.data.planNeedsAdjustment);
            setUpdatingPlan(false);
          }
        } else {
          // Handle case where message is missing
          setMessages(prev => [...prev, { 
            text: "Sorry, I couldn't process your request. Please try again.",
            isUser: false 
          }]);
        }
      } catch (error) {
        // Handle error case
        setMessages(prev => [...prev, { 
          text: "Sorry, there was an error processing your message. Please try again.",
          isUser: false 
        }]);
        console.error('Error sending message:', error);
      } finally {
        setIsWaitingForResponse(false);
      }
    }
  };

  const handleCloseChat = () => {
    setIsChatOpen(false);
  };

  useEffect(() => {
    console.log('websocket updatingPlan1', updatingPlan);
    if (!trainingPlanMetadata || !trainingObject || !trainingPaces || !updatingPlan) {
      console.log('websocket updatingPlan if', updatingPlan);
      return;
    }

    let ws: WebSocket | null = null;
    let reconnectAttempts = 0;
    const maxReconnectAttempts = 3;
    
    const connectWebSocket = () => {
      const wsUrl = `${process.env.REACT_APP_GENERATE_TRAINING_PLAN_API_BASE_URL}`;
      console.log('Attempting to connect to WebSocket:', wsUrl);
      
      ws = new WebSocket(wsUrl);

      ws.onopen = () => {
        setUpdatingPlan(true);
        console.log('WebSocket connection established');
        reconnectAttempts = 0; // Reset attempts on successful connection
        
        const payload = {
          "action": "regeneratetrainingplan",
          "goal": trainingPlanMetadata?.goal,
          "duration": trainingPlanMetadata?.duration,
          "targetTime": trainingPlanMetadata?.targetTime,
          "isKm": trainingPlanMetadata?.isKm,
          "trainingDays": trainingPlanMetadata?.trainingDays,
          "runningLevel": trainingPaces?.runningLevel || 'beginner',
          "trainingPlan": trainingPlan,
          "trainingPaces": trainingPaces
        };
        console.log('Sending WebSocket payload:', payload);
        ws?.send(JSON.stringify(payload));
      };

      console.log('trainingObject', trainingObject);
      console.log('trainingPaces', trainingPaces);
      console.log('trainingPlan123', trainingPlan);

      ws.onmessage = (event) => {
        console.log('WebSocket message received', event.data);
        try {
          const data = JSON.parse(event.data);
          if (data.message) {
            const workouts = data.message.split('\n').filter(Boolean);
            console.log('workouts', workouts);

            setUpdatedTrainingPlan(prevPlan => {
              const newPlan = [...prevPlan];
              console.log('handleCoachDescribeUpdatedTrainingPlan workouts', newPlan);
              workouts.forEach((weekWorkouts: string) => {
                const [weekNum, ...dailyWorkouts] = weekWorkouts.split(',');
                const weekNumber = parseInt(weekNum);

                // Skip if weekNumber is NaN
                if (isNaN(weekNumber)) return;

                // Remove existing workouts for this week if any
                // const filteredPlan = newPlan.filter(day => day.week !== weekNumber);

                // Create an array of non-empty workouts
                const nonEmptyWorkouts = dailyWorkouts.filter(workout => workout.trim());
                
                // Map the workouts to the selected training days
                const weekWorkoutDays = trainingPlanMetadata.trainingDays.map((day: string, index: number) => ({
                  week: weekNumber,
                  day: day,
                  workout: nonEmptyWorkouts[index] ? nonEmptyWorkouts[index].replace(/"/g, '') : 'Rest'
                }));

                // Only add valid week numbers
                if (weekNumber > 0) {
                  newPlan.push(...weekWorkoutDays);
                }
              });

              console.log('handleCoachDescribeUpdatedTrainingPlan workouts2', newPlan);

              // Filter out any remaining entries with null or NaN weeks
              return newPlan.filter(day => day.week && !isNaN(Number(day.week)));
            });
          }
        } catch (error) {
          console.error('Error parsing WebSocket message:', error);
        }
      };

      ws.onclose = (event) => {
        console.log(`WebSocket closed with code: ${event.code}`);
        
        if (!event.wasClean && reconnectAttempts < maxReconnectAttempts) {
          console.log(`Attempting to reconnect (${reconnectAttempts + 1}/${maxReconnectAttempts})...`);
          reconnectAttempts++;
          setTimeout(connectWebSocket, 1000 * reconnectAttempts); // Exponential backoff
        } else {
          console.log('WebSocket connection closed permanently');
          setSendCoachDescribeUpdatedTrainingPlan(true)
        }
      };

      ws.onerror = (error) => {
        console.error('WebSocket error:', error);
        console.error('WebSocket readyState:', ws?.readyState);
        // Don't set loading to false here, let the onclose handler manage it
      };
    };

    if (trainingPlanMetadata) {
      connectWebSocket();
    }

    return () => {
      if (ws) {
        console.log('Cleaning up WebSocket connection');
        ws.close();
      }
    };
  }, [trainingPlanMetadata, trainingObject, trainingPaces, updatingPlan]);

  return (
    <>
      <Container>
        <h1>{trainingPlanName || 'Training Plan'}</h1>
        {isLoading ? (
          <LoadingContainer>
            <LoadingSpinner />
            <div>Loading your training plan...</div>
          </LoadingContainer>
        ) : (
          <>
            <Table>
              <thead>
                <tr>
                  <Th>Week</Th>
                  {daysOfWeek.map(day => <Th key={day}>{day}</Th>)}
                </tr>
              </thead>
              <tbody>
                {processedTrainingPlan.map((week, weekIndex) => (
                  <tr key={weekIndex}>
                    <WeekHeader>{week.week}</WeekHeader>
                    {daysOfWeek.map(day => {
                      const trainingDay = week.days.find(d => d.day === day);
                      console.log('trainingDay', trainingDay);
                      const isLastDay = weekIndex === processedTrainingPlan.length - 1 && day === 'Sunday';
                      const workoutDisplay = isLastDay ? 'Race Day!' : 
                        (trainingDay?.workout === 'Race Day!' ? 'Rest' : (trainingDay?.workout || 'Rest'));
                      
                      console.log('Day status:', {
                        day,
                        status: trainingDay?.status,
                        fullDay: trainingDay
                      });
                      
                      return (
                        <ClickableTd
                          key={`${weekIndex}-${day}`}
                          to={`/view-training-plan/${planId}/workout/${weekIndex}/${day}`}
                          state={{ 
                            trainingDay: trainingDay || { day, workout: workoutDisplay },
                            trainingPlan: trainingObject,
                            isKm,
                            weekIndex,
                            trainingPaces
                          }}
                          $status={trainingDay?.status}
                        >
                          {trainingDay? (
                            <>
                              <WorkoutText $workoutType={workoutDisplay}>{workoutDisplay}</WorkoutText>
                              <div>{trainingDay.estimatedTime || ''}</div>
                            </>
                          ) : (
                            <WorkoutText>{workoutDisplay}</WorkoutText>
                          )}
                        </ClickableTd>
                      );
                    })}
                  </tr>
                ))}
              </tbody>
            </Table>
            <ButtonContainer>
              <StyledButton onClick={() => navigate('/view-training-plans')}>
                Back to Training Hub
              </StyledButton>
            </ButtonContainer>
          </>
        )}
      </Container>
      {updatingPlan && (
        <>
          <h2>Updated Training Plan</h2>
          <Table>
            <thead>
              <tr>
                <Th>Week</Th>
                {daysOfWeek.map(day => <Th key={day}>{day}</Th>)}
              </tr>
            </thead>
            <tbody>
              {[...Array(numberOfWeeks)].map((_, weekIndex) => (
                <tr key={weekIndex + 1}>
                  <WeekHeader>Week {weekIndex + 1}</WeekHeader>
                  {daysOfWeek.map((day) => (
                    <Td key={`${weekIndex + 1}-${day}`}>
                      {getWorkoutForDay(day, weekIndex + 1)}
                    </Td>
                  ))}
                </tr>
              ))}
            </tbody>
          </Table>
        </>
      )}
      <FloatingChatButton onClick={handleChatWithCoach}>
        <FaComments />
      </FloatingChatButton>
      {isChatOpen && (
        <ChatWindow>
          <ChatHeader>
            <span>Chat with Coach</span>
            <CloseButton onClick={handleCloseChat}>&times;</CloseButton>
          </ChatHeader>
          <ChatBody>
            <MessagesContainer>
              {messages.map((message, index) => (
                <Message key={index} $isUser={message.isUser}>
                  {message.text}
                </Message>
              ))}
              {isWaitingForResponse && (
                <TypingIndicator>
                  <span></span>
                  <span></span>
                  <span></span>
                </TypingIndicator>
              )}
            </MessagesContainer>
          </ChatBody>
          <ChatInput>
            <MessageInput
              type="text"
              value={newMessage}
              onChange={(e) => setNewMessage(e.target.value)}
              placeholder={isWaitingForResponse ? "Waiting for response..." : "Type your message..."}
              disabled={isWaitingForResponse}
              onKeyPress={(e) => e.key === 'Enter' && !isWaitingForResponse && handleSendMessage()}
            />
            <SendButton 
              onClick={handleSendMessage} 
              disabled={isWaitingForResponse}
            >
              {isWaitingForResponse ? "..." : "Send"}
            </SendButton>
          </ChatInput>
        </ChatWindow>
      )}
    </>
  );
};

export default ViewWholeTrainingPlan;
