/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react'
import { Helmet } from 'react-helmet-async';
import React, { forwardRef } from 'react';

const runTypes = [
  {
    type: 'Easy Run',
    description: 'Comfortable, conversational pace. Builds aerobic base and aids recovery.',
    icon: '🚶‍♂️'
  },
  {
    type: 'Tempo Run',
    description: 'Comfortably hard pace. Improves lactate threshold and race-specific endurance.',
    icon: '🏃'
  },
  {
    type: 'Interval Training',
    description: 'High-intensity repeats with recovery periods. Improves speed and VO2 max.',
    icon: '⚡'
  },
  {
    type: 'Long Run',
    description: 'Extended duration at easy pace. Builds endurance and mental strength.',
    icon: '🏃‍♂️'
  },
  {
    type: 'Recovery Run',
    description: 'Very easy pace. Promotes active recovery between harder workouts.',
    icon: '🌱'
  },
  {
    type: 'Rest Day',
    description: 'Complete rest or light stretching. Essential for recovery and adaptation.',
    icon: '😴'
  },
  {
    type: 'Crosstraining',
    description: 'Alternative cardio or strength activities. Maintains fitness while reducing running impact.',
    icon: '💪'
  },
  {
    type: 'Fartlek',
    description: 'Swedish for "speed play". Unstructured intervals mixing speeds and distances. Great for developing pace awareness and making training fun.',
    icon: '🎮'
  },
  {
    type: 'Progression Run',
    description: 'Start easy and gradually increase pace. Builds endurance while teaching pace control and finishing strong.',
    icon: '📈'
  }
]

interface RunTypesProps {
  isLoadingScreen?: boolean;
  id?: string;
}

const containerStyle = css`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 2rem;
  padding: 2rem;
  max-width: 1200px;
  margin: 0 auto;

  @media (max-width: 768px) {
    grid-template-columns: 1fr;
    gap: 1rem;
    padding: 1rem;
  }
`

const loadingContainerStyle = css`
  ${containerStyle}
  opacity: 0.7;
  pointer-events: none;
`

const cardStyle = css`
  background: white;
  border-radius: 12px;
  padding: 1.5rem;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  transition: transform 0.2s ease;
  
  &:hover {
    transform: translateY(-5px);
  }

  @media (max-width: 768px) {
    padding: 1rem;
  }
`

const titleStyle = css`
  font-size: 1.5rem;
  margin-bottom: 1rem;
  display: flex;
  align-items: center;
  gap: 0.5rem;

  @media (max-width: 768px) {
    font-size: 1.25rem;
  }
`

const descriptionStyle = css`
  color: #666;
  line-height: 1.6;
`

// Add a page heading for SEO
const pageHeadingStyle = css`
  text-align: center;
  margin: 2rem 0;
  color: #333;
  
  @media (max-width: 768px) {
    font-size: 1.5rem;
    margin: 1rem 0;
    padding: 0 1rem;
  }
`

const RunTypes = forwardRef<HTMLDivElement, RunTypesProps>(({ isLoadingScreen = false, id }, ref) => {
  return (
    <div ref={ref} id={id}>
      <Helmet>
        {!isLoadingScreen && (
          // Update title to be more specific and keyword-rich
          <title>Running Workout Types: Complete Guide to Training Runs | AIRunningTraining</title>
        )}
        {!isLoadingScreen && (
          <meta 
            name="description" 
            content="Learn about different types of running workouts: easy runs, tempo runs, intervals, long runs, and more. Complete guide with pacing tips and training advice for runners of all levels." 
          />
        )}
        {!isLoadingScreen && (
          <meta 
            name="keywords" 
            content="types of running workouts, running training, easy run, tempo run, interval training, long run, fartlek, progression run, running for beginners, running workout guide" 
          />
        )}
        
        {/* Update Open Graph tags */}
        {!isLoadingScreen && (
          <meta property="og:title" content="Running Workout Types: Complete Guide to Training Runs | AIRunningTraining" />
        )}
        {!isLoadingScreen && (
          <meta property="og:description" content="Learn about different types of running workouts: easy runs, tempo runs, intervals, long runs, and more. Complete guide with pacing tips and training advice for runners of all levels." />
        )}
        
        {/* Update canonical URL */}
        {!isLoadingScreen && (
          <link rel="canonical" href="https://airunningtraining.com/training-plan-generator/run-types" />
        )}
        
        {/* Add FAQ Schema markup */}
        {!isLoadingScreen && (
          <script type="application/ld+json">
            {JSON.stringify({
              "@context": "https://schema.org",
              "@type": "FAQPage",
              "mainEntity": [
                {
                  "@type": "Question",
                  "name": "What pace should I run my easy run?",
                  "acceptedAnswer": {
                    "@type": "Answer",
                    "text": "Easy runs should be performed at a conversational pace, typically 60-75% of your maximum heart rate. You should be able to speak in complete sentences while running. This usually means running 1-2 minutes per mile slower than your 5K race pace."
                  }
                },
                {
                  "@type": "Question",
                  "name": "What pace should I run my tempo run?",
                  "acceptedAnswer": {
                    "@type": "Answer",
                    "text": "Tempo runs should be performed at your 'comfortably hard' pace, approximately 85-90% of your maximum heart rate. This typically corresponds to the pace you could maintain for about an hour in a race, or roughly 25-30 seconds per mile slower than your 5K race pace."
                  }
                },
                {
                  "@type": "Question",
                  "name": "How long should my long run be?",
                  "acceptedAnswer": {
                    "@type": "Answer",
                    "text": "Your long run should typically be 20-30% of your total weekly mileage. For most recreational runners, this means 90-120 minutes of running. Beginners might start with 60 minutes, while advanced marathoners might run up to 180 minutes. Increase long run duration gradually, typically no more than 10% per week."
                  }
                },
                {
                  "@type": "Question",
                  "name": "What crosstraining is beneficial for runners?",
                  "acceptedAnswer": {
                    "@type": "Answer",
                    "text": "Beneficial crosstraining activities for runners include cycling, swimming, and strength training. Low-impact activities like cycling and swimming help maintain cardiovascular fitness while reducing impact stress. Strength training, particularly focusing on core, legs, and glutes, helps prevent injuries and improve running economy. Yoga and Pilates can also enhance flexibility and core strength."
                  }
                },
                {
                  "@type": "Question",
                  "name": "What can I do to improve my vo2max as a runner?",
                  "acceptedAnswer": {
                    "@type": "Answer",
                    "text": "To improve VO2max, incorporate high-intensity interval training (HIIT) into your routine 1-2 times per week. This could include track intervals at 95-100% effort for 2-5 minutes with equal recovery time, hill repeats, or fartlek training. Combine these harder efforts with adequate easy running and recovery to see maximum benefits. Consistency in training over several weeks is key for VO2max improvements."
                  }
                },
                {
                  "@type": "Question",
                  "name": "What is a fartlek run and how do I do it?",
                  "acceptedAnswer": {
                    "@type": "Answer",
                    "text": "Fartlek, Swedish for 'speed play', is an unstructured form of interval training. During a fartlek run, you vary your pace based on feel, alternating between faster and slower segments. For example, you might sprint to a tree, jog to a lamppost, run moderately to the next corner, etc. This makes training more enjoyable while improving speed and endurance. It's especially good for beginners as there's no pressure to hit specific paces or distances."
                  }
                },
                {
                  "@type": "Question",
                  "name": "What is a progression run and how should I pace it?",
                  "acceptedAnswer": {
                    "@type": "Answer",
                    "text": "Progression runs start easy and gradually increase pace. They build endurance while teaching pace control and finishing strong. To pace a progression run, start at a comfortable conversational pace and gradually increase the pace as you get more comfortable with the run. The goal is to maintain a comfortable conversational pace while running the entire run."
                  }
                }
              ]
            })}
          </script>
        )}
      </Helmet>

      <main>
        {!isLoadingScreen ? (
          <h1 css={pageHeadingStyle}>Types of Running Workouts: A Complete Training Guide</h1>
        ) : (
          <h1 css={pageHeadingStyle}>Types of Running Workouts to Look Out For in Your Training Plan</h1>
        )}
        {isLoadingScreen && (
          <p css={descriptionStyle}>
            While your personalized training plan is being generated, let's explore the different types of running workouts you'll encounter. From easy recovery runs that build your aerobic base, to tempo runs that improve your lactate threshold, to interval training that boosts your speed and VO2max - each workout type serves a specific purpose in your training. Your plan will include a strategic mix of these workouts to help you reach your goals while maintaining proper recovery and preventing injury.
          </p>
        )}
        <div css={isLoadingScreen ? loadingContainerStyle : containerStyle}>
          {runTypes.map((run) => (
            <article key={run.type} css={cardStyle}>
              <h2 css={titleStyle}>
                <span>{run.icon}</span>
                {run.type}
              </h2>
              <p css={descriptionStyle}>{run.description}</p>
            </article>
          ))}
        </div>
      </main>
    </div>
  )
})

export default RunTypes
