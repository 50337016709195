import React from 'react';
import { useState, useEffect } from 'react';
import styled from '@emotion/styled';
import { Helmet } from 'react-helmet';
import { FaCheckCircle, FaComments, FaUserCog, FaTrophy, FaSpinner } from 'react-icons/fa';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../../contexts/AuthContext';


// Styled Components
const Container = styled.div`
  padding: 50px 20px;
  max-width: 1200px;
  margin: 0 auto;
  text-align: center;
`;

const Header = styled.header`
  margin-bottom: 40px;
`;

const Title = styled.h1`
  font-size: 3rem;
  color: #2a9d8f;
  margin-bottom: 10px;
`;

const SubTitle = styled.p`
  font-size: 1.5rem;
  color: #264653;
`;

const FeaturesGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 30px;
  margin: 50px 0;
`;

const FeatureCard = styled.div`
  background-color: #ffffff;
  padding: 30px 20px;
  border-radius: 10px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease;
  
  &:hover {
    transform: translateY(-10px);
  }
`;

const FeatureIcon = styled.div`
  font-size: 3rem;
  color: #2a9d8f;
  margin-bottom: 20px;
`;

const FeatureTitle = styled.h3`
  font-size: 1.5rem;
  color: #264653;
  margin-bottom: 15px;
`;

const FeatureDescription = styled.p`
  font-size: 1rem;
  color: #555555;
`;

const PricingSection = styled.section`
  background-color: #e9f5f4;
  padding: 50px 20px;
  border-radius: 10px;
`;

const PricingTitle = styled.h2`
  font-size: 2rem;
  color: #2a9d8f;
  margin-bottom: 30px;
`;

const PricingPlans = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 30px;
`;

const PlanCard = styled.div<{ mostPopular?: boolean }>`
  background-color: #ffffff;
  padding: 30px 20px;
  border: ${({ mostPopular }) => (mostPopular ? '2px solid #2a9d8f' : '1px solid #ddd')};
  border-radius: 10px;
  width: 300px;
  position: relative;
  
  ${({ mostPopular }) =>
    mostPopular &&
    `
    box-shadow: 0 6px 10px rgba(42, 157, 143, 0.2);
  `}
`;

const PlanTitle = styled.h3`
  font-size: 1.5rem;
  color: #264653;
  margin-bottom: 20px;
`;

const PlanPrice = styled.p`
  font-size: 2rem;
  color: #e76f51;
  margin-bottom: 20px;
`;

const PlanFeatures = styled.ul`
  list-style: none;
  padding: 0;
  text-align: left;
  margin-bottom: 30px;
`;

const PlanFeature = styled.li`
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  color: #555555;

  svg {
    margin-right: 10px;
    color: #2a9d8f;
  }
`;

const LoadingSpinner = styled(FaSpinner)`
  animation: spin 1s linear infinite;
  margin-left: 8px;
  
  @keyframes spin {
    100% {
      transform: rotate(360deg);
    }
  }
`;

const BaseButton = styled.button`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 12px 30px;
  background-color: #2a9d8f;
  color: #ffffff;
  border: none;
  border-radius: 5px;
  text-decoration: none;
  font-size: 1rem;
  cursor: pointer;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: #21867a;
  }

  &:disabled {
    background-color: #93c5bf;
    cursor: not-allowed;
  }
`;

const BaseLink = styled.a`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 12px 30px;
  background-color: #2a9d8f;
  color: #ffffff;
  border: none;
  border-radius: 5px;
  text-decoration: none;
  font-size: 1rem;
  cursor: pointer;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: #21867a;
  }

  &:disabled {
    background-color: #93c5bf;
    cursor: not-allowed;
  }
`;

const CTAButton = ({ href, ...props }: { href?: string } & any) =>
  href ? <BaseLink as="a" href={href} {...props} /> : <BaseButton {...props} />;

const FAQSection = styled.section`
  margin-top: 50px;
  text-align: left;
`;

const FAQTitle = styled.h2`
  font-size: 2rem;
  color: #2a9d8f;
  margin-bottom: 30px;
`;

const FAQItem = styled.div`
  margin-bottom: 20px;
`;

const FAQQuestion = styled.h4`
  font-size: 1.2rem;
  color: #264653;
  margin-bottom: 10px;
`;

const FAQAnswer = styled.p`
  font-size: 1rem;
  color: #555555;
`;

const Footer = styled.footer`
  margin-top: 50px;
  padding: 20px;
  background-color: #f0f2f5;
  border-radius: 10px;
`;

const FooterText = styled.p`
  font-size: 0.9rem;
  color: #555555;
`;

// Component
const PremiumFeatures: React.FC = () => {
    const { user, isPremium } = useAuth();
    const [isLoading, setIsLoading] = useState(false);
    const navigate = useNavigate();

    const handleSubscribe = async () => {
        if (isLoading) return;
        setIsLoading(true);
        
        if (!user) {
          alert('You must be signed in to subscribe.');
          navigate('/login');
          setIsLoading(false);
          return;
        }
        try {
          const priceId = process.env.REACT_APP_ENV === 'dev' ? 'price_1QX11609u1yk0lrOCHr2r4fQ' : 'price_1QWe3K09u1yk0lrOz4iAsYlC';
          const response = await fetch('https://c3xzkliim3.execute-api.eu-north-1.amazonaws.com/stripepaymentrn', {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({
              userId: user.username,
              email: user.email || '',
              env: process.env.REACT_APP_ENV || '',
              priceId: priceId,
            }),
          });
    
          if (!response.ok) {
            throw new Error('Failed to create checkout session');
          }
    
          const { checkoutUrl, sessionId, customerId } = await response.json();
    
          if (!checkoutUrl) {
            throw new Error('No redirect URL received from the server');
          }
    
          // Store sessionId and customerId in localStorage for later use
          localStorage.setItem('stripeSessionId', sessionId);
          localStorage.setItem('stripeCustomerId', customerId);
    
          // Redirect to the Stripe Checkout page
          window.location.href = checkoutUrl;
    
          // After successful subscription, update the premium status
        //   await checkPremiumStatus();
        } catch (error) {
          console.error('Subscription error:', error);
          // Handle the error (e.g., show an error message to the user)
          alert('An error occurred while processing your subscription. Please try again.');
        } finally {
          setIsLoading(false);
        }
      };
  return (
    <>
      <Helmet>
        <title>AI Running Coach | AI Running Training</title>
        <meta 
          name="description" 
          content="Get personalized running advice with live chat access to experienced running coaches. Receive real-time guidance, training adjustments, and motivation to achieve your running goals."
        />
      </Helmet>
      <Container>
        <Header>
          <Title>Live Chat with Your AI Running Coach</Title>
          <SubTitle>Get Expert Guidance When You Need It Most</SubTitle>
        </Header>

        <FeaturesGrid>
          <FeatureCard>
            <FeatureIcon>
              <FaComments />
            </FeatureIcon>
            <FeatureTitle>Real-Time Support</FeatureTitle>
            <FeatureDescription>
              Connect instantly with an AI running coach who can answer your questions and provide immediate feedback when you need it most.
            </FeatureDescription>
          </FeatureCard>

          <FeatureCard>
            <FeatureIcon>
              <FaUserCog />
            </FeatureIcon>
            <FeatureTitle>Personalized Advice</FeatureTitle>
            <FeatureDescription>
              Receive customized guidance based on your specific goals, current fitness level, and running challenges. Our AI coach helps you navigate injuries, training plateaus, and race preparation.
            </FeatureDescription>
          </FeatureCard>

          <FeatureCard>
            <FeatureIcon>
              <FaTrophy />
            </FeatureIcon>
            <FeatureTitle>Ongoing Motivation</FeatureTitle>
            <FeatureDescription>
              Stay accountable and motivated with regular check-ins and encouragement from your coach. Celebrate victories and get support during challenging times in your training journey.
            </FeatureDescription>
          </FeatureCard>
        </FeaturesGrid>

        <PricingSection>
          <PricingTitle>Coaching Plans</PricingTitle>
          <PricingPlans>
            <PlanCard>
              <PlanTitle>Basic</PlanTitle>
              <PlanPrice>FREE</PlanPrice>
              <PlanFeatures>
                <PlanFeature><FaCheckCircle /> AI Training Plan</PlanFeature>
              </PlanFeatures>
              {user ? <CTAButton href="/running-route-planner">Current Plan</CTAButton> : <CTAButton href="/login">Sign Up</CTAButton>}
            </PlanCard>
            <PlanCard mostPopular>
              <PlanTitle>Ai Running Training Premium</PlanTitle>
              <PlanPrice>$4.99/month</PlanPrice>
              <PlanFeatures>
                <PlanFeature><FaCheckCircle /> AI Training Plan</PlanFeature>
                <PlanFeature><FaCheckCircle /> Unlimited Coach Chat Access</PlanFeature>
                <PlanFeature><FaCheckCircle /> 24/7 Response Time</PlanFeature>
                <PlanFeature><FaCheckCircle /> Race Strategy Consultation</PlanFeature>
                <PlanFeature><FaCheckCircle /> Injury Prevention Advice</PlanFeature>
                <PlanFeature><FaCheckCircle /> Update Training Plan Based on Coach Advice</PlanFeature>
                <PlanFeature><FaCheckCircle /> Auto-Adjusted Training Plan Based on Performance</PlanFeature>
              </PlanFeatures>
              {user ? (
                isPremium ? (
                  <CTAButton onClick={handleSubscribe}>Current Plan</CTAButton>
                ) : (
                  <CTAButton 
                    onClick={handleSubscribe} 
                    disabled={isLoading}
                  >
                    {isLoading ? (
                      <>
                        Processing<LoadingSpinner />
                      </>
                    ) : (
                      'Start Chatting Today'
                    )}
                  </CTAButton>
                )
              ) : (
                <CTAButton href="/login">Sign Up to Chat</CTAButton>
              )}
            </PlanCard>
          </PricingPlans>
        </PricingSection>

        <FAQSection>
          <FAQTitle>Frequently Asked Questions</FAQTitle>
          <FAQItem>
            <FAQQuestion>How quickly will the AI coach respond to my messages?</FAQQuestion>
            <FAQAnswer>
              The AI coach typically responds within seconds for any query.
            </FAQAnswer>
          </FAQItem>
          <FAQItem>
            <FAQQuestion>What separates the AI coach from other running coaches?</FAQQuestion>
            <FAQAnswer>
              The AI coach is a personalized coach that is tailored to your specific needs and goals. It is always available and can provide you with personalized advice and support.
            </FAQAnswer>
          </FAQItem>
          <FAQItem>
            <FAQQuestion>What is the difference between this AI coach and other AI coaches?</FAQQuestion>
            <FAQAnswer>
              Our AI coach is specifically trained on running expertise and integrates with your training plan to provide highly contextual advice. It understands your training history, current fitness level, and goals to give personalized recommendations. Unlike generic AI coaches, ours focuses exclusively on running and can provide detailed guidance on training paces, race strategies and injury prevention - all tailored to your specific training journey.
            </FAQAnswer>
          </FAQItem>
        </FAQSection>

        <Footer>
          <FooterText>&copy; {new Date().getFullYear()} AI Running Training. All rights reserved.</FooterText>
        </Footer>
      </Container>
    </>
  );
};

export default PremiumFeatures;
