import React, { useEffect, useState } from 'react';
import { useParams, Navigate, useNavigate, useLocation } from 'react-router-dom';
import styled from '@emotion/styled';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import { FaShare, FaFacebookF, FaTwitter, FaLinkedinIn, FaWhatsapp } from 'react-icons/fa';
import { sendEvent } from '../utils/analytics';
import axios from 'axios';

// Update the Container to have a max-width that accommodates both the blog and sidebar
const Container = styled.div`
  // max-width: 1200px;
  width: 100%;
  margin: 0 auto;
  padding: 20px;
  box-sizing: border-box;

  @media (max-width: 1024px) {
    padding: 15px;
    width: 95%;
  }
`;

// Update ContentWrapper to handle the new layout
const ContentWrapper = styled.div`
  display: flex;
  gap: 30px;

  @media (max-width: 1200px) {
    flex-direction: column;
  }
`;

const BlogContainer = styled.div`
  flex: 1;
  background-color: #f0f4f8;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  padding: 20px;

  @media (max-width: 1024px) {
    width: 100%;
  }
`;

const Header = styled.h1`
  text-align: center;
  color: #264653;
  font-size: 2.5rem;
  margin-bottom: 30px;
  font-family: 'Roboto', sans-serif;
  font-weight: bold;

  @media (max-width: 768px) {
    font-size: 2rem;
    margin-bottom: 20px;
  }
`;

const BlogContent = styled.div`
  font-size: 1rem;
  color: #555555;
  line-height: 1.6;
  font-family: 'Roboto', sans-serif;

  img {
    max-width: 100%;
    height: auto;
    display: block;
    margin: 20px 0;
  }

  h2, h3 {
    word-wrap: break-word;
  }

  p, ul, ol {
    word-wrap: break-word;
    overflow-wrap: break-word;
  }

  iframe {
    display: block;
    margin: 20px auto;
    max-width: 100%;
  }
`;

// New styled components for the layout and related blogs
const MainContent = styled.div`
  flex: 2;
  order: 2;

  @media (max-width: 1200px) {
    order: 1;
  }
`;

const LeftSidebar = styled.div`
  width: 20%;
  order: 1;

  @media (max-width: 1200px) {
    width: 100%;
    order: 3;
  }
`;

const RightSidebar = styled.div`
  width: 20%;
  order: 3;

  @media (max-width: 1200px) {
    width: 100%;
    order: 2;
  }
`;

const RelatedBlogsContainer = styled.div`
  background-color: #ffffff;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  padding: 20px;
`;

const RelatedBlogsHeader = styled.h2`
  font-size: 1.5rem;
  color: #264653;
  margin-bottom: 20px;
  text-align: center;
  `;

const RelatedBlogTitle = styled.h3`
  font-size: 1rem;
  color: #2a9d8f;
  margin-bottom: 5px;
  transition: text-decoration 0.3s ease;

  &:hover {
    text-decoration: underline;
  }
`;

const RelatedBlogItem = styled(Link)`
  display: flex;
  align-items: center;
  margin-bottom: 20px;
  padding: 10px;
  border-bottom: 1px solid #e0e0e0;
  text-decoration: none;
  color: inherit;
  transition: background-color 0.3s ease;

  &:last-child {
    margin-bottom: 0;
    border-bottom: none;
  }

  &:hover {
    background-color: #f0f4f8;
  }
`;

const RelatedBlogThumbnail = styled.img`
  width: 80px;
  height: 80px;
  object-fit: cover;
  border-radius: 5px;
  margin-right: 15px;
`;

const RelatedBlogInfo = styled.div`
  flex: 1;
`;

const RelatedBlogTopic = styled(Link)`
  display: inline-block;
  background-color: #e0f7fa;
  color: #006064;
  padding: 3px 8px;
  border-radius: 12px;
  font-size: 0.7rem;
  margin-bottom: 5px;
  text-decoration: none;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: #b2ebf2;
  }
`;

const RelatedBlogLink = styled.span`
  color: #264653;
  font-weight: bold;
  font-size: 0.9rem;
  transition: text-decoration 0.3s ease;
`;

// New Styled Component for BlogImage
const BlogImage = styled.img`
  max-width: 100%;
  height: auto;
  display: block;
  margin: 20px auto;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
`;

// Add these new styled components
const AdContainer = styled.div`
  background-color: #ffffff;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  padding: 20px;
  margin-bottom: 20px;
`;

const AdHeader = styled.h2`
  font-size: 1.5rem;
  color: #264653;
  margin-bottom: 20px;
  text-align: center;
`;

const AdContent = styled.div`
  text-align: center;
`;

const AdImage = styled.img`
  max-width: 100%;
  height: auto;
  border-radius: 5px;
  margin-bottom: 15px;
`;

const AdButton = styled(Link)`
  display: inline-block;
  background-color: #2a9d8f;
  color: #ffffff;
  padding: 10px 20px;
  border-radius: 5px;
  text-decoration: none;
  font-weight: bold;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: #264653;
  }
`;

const ShareButtonsContainer = styled.div`
  display: flex;
  justify-content: center;
  gap: 15px;
  margin-top: 20px;
`;

const ShareButton = styled.button`
  border: none;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: background-color 0.3s;
  font-size: 18px;

  @media (max-width: 768px) {
    width: 50px;
    height: 50px;
  }
`;

const ShareIcon = styled.span`
  font-size: 18px;

  @media (max-width: 768px) {
    font-size: 24px;
  }
`;

const FacebookButton = styled(ShareButton)`
  background-color: #3b5998;
  color: white;
  &:hover {
    background-color: #2d4373;
  }
`;

const TwitterButton = styled(ShareButton)`
  background-color: #1da1f2;
  color: white;
  &:hover {
    background-color: #0c85d0;
  }
`;

const WhatsAppButton = styled(ShareButton)`
  background-color: #25d366;
  color: white;
  &:hover {
    background-color: #128c7e;
  }
`;

// New styled component for Share CTA
const ShareCTA = styled.div`
  text-align: center;
  margin-bottom: 15px;
  font-size: 1.1rem;
  color: #264653;
  font-weight: bold;
`;

// Add this new styled component for the secondary ad
const SecondaryAdContainer = styled(AdContainer)`
  margin-top: 20px;
`;

const BlogPost: React.FC = () => {
  const { topic, slug } = useParams<{ topic: string; slug: string }>();
  const navigate = useNavigate();
  const location = useLocation();
  const [blogPostImageUrl, setBlogPostImageUrl] = useState<string>('');
  console.log('blogPostImageUrl', blogPostImageUrl);
  const formattedTopic = topic?.toLowerCase().replace(/\s+/g, '-');
  const [blogContent, setBlogContent] = useState<string>('');
  const [tags, setTags] = useState<string[]>([]);
  const [title, setTitle] = useState<string>('');
  const [SEOTitle, setSEOTitle] = useState<string>('');
  const [summary, setSummary] = useState<string>('');
  const [createdAt, setCreatedAt] = useState<string>('');
  const [relatedBlogs, setRelatedBlogs] = useState<Array<{
    slug: string;
    title: string;
    topic: string;
    image: string;
  }>>([]);
  const [availableLanguages, setAvailableLanguages] = useState<string[]>([]);

  const runningRoutePlannerImage = 'https://public-blog-images-mlp.s3.eu-north-1.amazonaws.com/10-best-running-route-planners.png';
  const calorieBurnCalculatorImage = 'https://public-blog-images-mlp.s3.eu-north-1.amazonaws.com/running-calories-burned.png';
  const heartRateTrainingZonesImage = 'https://public-blog-images-mlp.s3.eu-north-1.amazonaws.com/running-heart-rate-zones.png';
  const trainingPlanImage = 'https://public-blog-images-mlp.s3.eu-north-1.amazonaws.com/training-plan-ad.png';

  // Move both useEffect hooks to the top, before any conditional returns
  useEffect(() => {
    // Validate both topic and slug exist
    if (!topic || !slug) {
      navigate('/404', { replace: true });
      return;
    }

    const formattedTopic = topic.toLowerCase().replace(/\s+/g, '-');
    const validTopics = ['race-guides', 'nutrition', 'running-routes', 'running-training', 'running-injuries', 'parkrun'];
    
    // Check if topic is valid
    if (!validTopics.includes(formattedTopic)) {
      navigate('/404', { replace: true });
      return;
    }
  }, [topic, slug, navigate]);

  useEffect(() => {
    const fetchBlogPost = async () => {
      // Get language from URL path - first segment after the leading slash
      const lang = location.pathname.split('/')[1];
      // If the first segment is a 2-letter language code, use it; otherwise default to 'en'
      const validLang = /^[a-z]{2}$/.test(lang) ? lang : 'en';

      try {
        // Set the Content-Language meta tag
        document.documentElement.lang = validLang;
        const metaContentLanguage = document.querySelector('meta[http-equiv="Content-Language"]');
        if (metaContentLanguage) {
          metaContentLanguage.setAttribute('content', validLang);
        } else {
          const newMetaContentLanguage = document.createElement('meta');
          newMetaContentLanguage.setAttribute('http-equiv', 'Content-Language');
          newMetaContentLanguage.setAttribute('content', validLang);
          document.head.appendChild(newMetaContentLanguage);
        }
        
        const response = await axios.get(`${process.env.REACT_APP_BLOGS_API_BASE_URL}/getBlog`, {
          params: { slug: slug, lang: validLang },
        });
        
        const data = JSON.parse(response.data.body);
        
        console.log('currentlogging data', data);
        console.log('currentlogging data.content', data.content);
        // If no valid data is returned, redirect to English if we're not already there
        if (!data || !data.content) {
          console.log('currentlogging in if !data || !data.content')
          if (validLang !== 'en') {
            console.log('currentlogging in if validLang !== en')
            const englishPath = location.pathname.split('/').slice(2).join('/');
            console.log('currentlogging englishPath', englishPath);
            window.location.href = `/${englishPath}`;
            return;
          }
          throw new Error('No content found');
        }

        // Set content if we have valid data
        setBlogContent(data.content);
        setTags(data.tags);
        setTitle(data.title);
        setSEOTitle(data.SEOTitle);
        setSummary(data.summary);
        setCreatedAt(data.createdAt);
        setAvailableLanguages(data.availableLanguages || []);

        // Handle language redirect
        handleLanguageRedirect(data.availableLanguages || [], location.pathname);

      } catch (error) {
        console.error('Error fetching blog post:', error);
        // Only redirect to English if we're not already there and there was an error
        if (validLang !== 'en') {
          const englishPath = location.pathname.split('/').slice(2).join('/');
          window.location.href = `/${englishPath}`;
        }
      }
    };
    
    fetchBlogPost();

    if (title || SEOTitle) {
      document.title = SEOTitle || title;
      const metaDescription = document.querySelector('meta[name="description"]');
      if (metaDescription) {
        metaDescription.setAttribute("content", summary.slice(0, 150));
      } else {
        const newMetaDescription = document.createElement('meta');
        newMetaDescription.name = "description";
        newMetaDescription.content = summary.slice(0, 150);
        document.head.appendChild(newMetaDescription);
      }
    }
  }, [slug, location.pathname]);
 
  useEffect(() => {
    const fetchRelatedBlogs = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_BLOGS_API_BASE_URL}/getAllBlogs`);
        const allBlogs = JSON.parse(response.data.body).blog_posts;
        const imageUrls = JSON.parse(response.data.body).image_urls;
        setBlogPostImageUrl(imageUrls.find((url: string) => url.includes(slug ?? '')));
        
        // Filter related blogs based on topic (case-insensitive)
        const related = allBlogs
          .filter((post: any) => {
            if (!topic || !post.topic) return false; // Add null check for topic
            // Normalize both topics by converting to lowercase and replacing both spaces and hyphens with hyphens
            const normalizedPostTopic = post.topic.toLowerCase().replace(/[\s-]+/g, '-');
            const normalizedCurrentTopic = topic.toLowerCase().replace(/[\s-]+/g, '-');
            return post.slug !== slug && normalizedPostTopic === normalizedCurrentTopic;
          })
          .slice(0, 3)
          .map((post: any) => ({
            slug: post.slug,
            title: post.title,
            topic: post.topic,
            image: imageUrls.find((url: string) => url.includes(post.slug)) || `https://public-blog-images-mlp.s3.eu-north-1.amazonaws.com/${post.slug}.jpg`
          }));

        console.log('currentlogging related', related);

        setRelatedBlogs(related);
      } catch (error) {
        console.error('Error fetching related blogs:', error);
      }
    };

    if (topic && slug) {
      fetchRelatedBlogs();
    }
  }, [topic, slug]);

  // Check if the post has the 'Running Weight Loss' tag
  const showWeightLossAd = tags?.includes('Running Weight Loss');
  const showHeartRateTrainingAd = tags?.includes('Heart Rate Training');
  const showTrainingPlanAd = topic?.includes('running-training') || topic?.includes('race-guides');

  const canonicalUrl = `https://airunningtraining.com/blog/${formattedTopic}/${slug}`;

  console.log('canonicalUrl', canonicalUrl);

  const shareOnFacebook = () => {
    sendEvent('share_blog_post', { platform: 'facebook', post_title: title });
    window.open(`https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(canonicalUrl)}`, '_blank');
  };

  const shareOnTwitter = () => {
    sendEvent('share_blog_post', { platform: 'twitter', post_title: title });
    window.open(`https://twitter.com/intent/tweet?text=${encodeURIComponent(title)}&url=${encodeURIComponent(canonicalUrl)}`, '_blank');
  };

  const shareOnWhatsApp = () => {
    sendEvent('share_blog_post', { platform: 'whatsapp', post_title: title });
    window.open(`https://wa.me/?text=${encodeURIComponent(`${title} ${canonicalUrl}`)}`, '_blank');
  };

  const handleRoutePlannerClick = () => {
    sendEvent('click_route_planner', { location: 'blog_post_sidebar' });
  };

  const handleCalorieCalculatorClick = () => {
    sendEvent('click_calorie_calculator', { location: 'blog_post_sidebar' });
  };

  console.log('slug', slug);
  console.log('SEOTitle', SEOTitle);
  console.log('topic', topic);
  console.log('title', title);
  console.log('summary', summary);

  const handleLanguageRedirect = (availableLanguages: string[], currentPath: string) => {
    // Extract city from slug format "running-routes-in-city"
    const city = slug?.split('running-routes-in-')[1];
    const preferredLanguage = navigator.language.split('-')[0];
    
    if (availableLanguages.includes(preferredLanguage) && preferredLanguage !== 'en') {
      window.location.href = `/${preferredLanguage}${currentPath}`;
    } else {
      console.log('currentlogging in else', preferredLanguage, slug)
      // Only make the translation request if we have a valid city
      if (city && slug && preferredLanguage !== 'en') {
        axios.post('https://q571g1v1jg.execute-api.eu-north-1.amazonaws.com/prod/translate', {
          city: city,
          langCode: preferredLanguage,
          slug: slug,
        });
      }
    }
  };

  return (
    <Container>
      <Helmet>
        {/* Update the title to include your site name */}
        <title>{`${SEOTitle || title} | AI Running Training`}</title>
        {/* Add a meta description if not already present */}
        <meta name="description" content={summary.slice(0, 150)} />
        <link rel="canonical" href={canonicalUrl} />
        
        {/* Add language tag if not already present in your HTML */}
        <html lang="en" />
        
        {/* Add Open Graph tags */}
        <meta property="og:title" content={title} />
        <meta property="og:description" content={summary.slice(0, 150)} />
        <meta property="og:url" content={canonicalUrl} />
        <meta property="og:type" content="article" />
        <meta property="og:image" content={blogPostImageUrl} />
        <meta property="og:site_name" content="AI Running Training" />
        
        {/* Add Twitter Card tags */}
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content={title} />
        <meta name="twitter:description" content={summary.slice(0, 150)} />
        <meta name="twitter:image" content={blogPostImageUrl} />
        
        {/* Add schema.org JSON-LD structured data */}
        <script type="application/ld+json">
          {JSON.stringify({
            "@context": "https://schema.org",
            "@type": "BlogPosting",
            "headline": title,
            "description": summary.slice(0, 150),
            "author": {
              "@type": "Person",
              "name": "AI Running Training"
            },
            "datePublished": createdAt,
            "image": blogPostImageUrl,
            "url": canonicalUrl,
            "keywords": topic,
            "publisher": {
              "@type": "Organization",
              "name": "AI Running Training",
              "logo": {
                "@type": "ImageObject",
                "url": "https://airunningtraining.com/logo.png"
              }
            }
          })}
        </script>
        
        {/* Add hreflang tags for all available languages */}
        {availableLanguages.map(lang => (
          <link 
            key={lang}
            rel="alternate" 
            hrefLang={lang}
            href={`https://airunningtraining.com/${lang}/blog/${formattedTopic}/${slug}`}
          />
        ))}
        {/* Add x-default hreflang for the default English version */}
        <link 
          rel="alternate" 
          hrefLang="x-default"
          href={`https://airunningtraining.com/blog/${formattedTopic}/${slug}`}
        />
      </Helmet>
      <ContentWrapper>
        <LeftSidebar>
          <AdContainer>
            <AdHeader>Plan Your Run</AdHeader>
            <AdContent>
              <AdImage src={runningRoutePlannerImage} alt="Running Route Planner" />
              <AdButton to="/running-route-planner" onClick={handleRoutePlannerClick}>
                Try Our Route Planner
              </AdButton>
            </AdContent>
          </AdContainer>
          {showWeightLossAd && (
            <SecondaryAdContainer>
              <AdHeader>Try our Calories Burned Calculator</AdHeader>
              <AdContent>
                <AdImage src={calorieBurnCalculatorImage} alt="Calorie Burn Calculator" />
                <AdButton to="/calorieBurnCalculator" onClick={handleCalorieCalculatorClick}>
                  Use Our Calorie Calculator
                </AdButton>
              </AdContent>
            </SecondaryAdContainer>
          )}
          {showHeartRateTrainingAd && (
            <SecondaryAdContainer>
              <AdHeader>Optimize Your Heart Rate Training</AdHeader>
              <AdContent>
                <AdImage src={heartRateTrainingZonesImage} alt="Heart Rate Training Zones" />
                <AdButton to="/heartRateZoneCalculator">Calculate Your Heart Rate Zones</AdButton>
              </AdContent>
            </SecondaryAdContainer>
          )}
          {showTrainingPlanAd && (
            <SecondaryAdContainer>
              <AdHeader>Train for Your Next Race</AdHeader>
              <AdContent>
                <AdImage src={trainingPlanImage} alt="Running Training Plan Generator" />
                <AdButton to="/ai-training-plan-generator">Generate Your Training Plan</AdButton>
              </AdContent>
            </SecondaryAdContainer>
          )}
        </LeftSidebar>
        <MainContent>
          <BlogContainer>
            <Header>{title}</Header>
            <BlogImage src={blogPostImageUrl} alt={title} />
            <BlogContent dangerouslySetInnerHTML={{ __html: blogContent }} />
            <ShareCTA>
              <FaShare /> Did you find this helpful? Share it with your friends!
            </ShareCTA>
            <ShareButtonsContainer>
              <FacebookButton onClick={shareOnFacebook} aria-label="Share on Facebook">
                <ShareIcon><FaFacebookF /></ShareIcon>
              </FacebookButton>
              <TwitterButton onClick={shareOnTwitter} aria-label="Share on Twitter">
                <ShareIcon><FaTwitter /></ShareIcon>
              </TwitterButton>
              <WhatsAppButton onClick={shareOnWhatsApp} aria-label="Share on WhatsApp">
                <ShareIcon><FaWhatsapp /></ShareIcon>
              </WhatsAppButton>
            </ShareButtonsContainer>
          </BlogContainer>
        </MainContent>
        <RightSidebar>
          <RelatedBlogsContainer>
            <RelatedBlogsHeader>Related Blogs</RelatedBlogsHeader>
            {relatedBlogs.map(blog => (
              <RelatedBlogItem 
                key={blog.slug} 
                to={`/blog/${blog.topic.toLowerCase().replace(/\s+/g, '-')}/${blog.slug}`}
              >
                <RelatedBlogThumbnail src={blog.image} alt={blog.title} />
                <RelatedBlogInfo>
                  <RelatedBlogTitle>{blog.title}</RelatedBlogTitle>
                  <RelatedBlogTopic 
                    to={`/blogs/${blog.topic.toLowerCase().replace(/\s+/g, '-')}`}
                  >
                    {blog.topic.split('-').map(word => 
                      word.charAt(0).toUpperCase() + word.slice(1)
                    ).join(' ')}
                  </RelatedBlogTopic>
                </RelatedBlogInfo>
              </RelatedBlogItem>
            ))}
          </RelatedBlogsContainer>
        </RightSidebar>
      </ContentWrapper>
    </Container>
  );
};

// Export remains unchanged
export default BlogPost;