import React, { useState, useEffect, useCallback, useRef } from 'react';
import styled from '@emotion/styled';
import { LatLng } from 'leaflet';
import { FaUndo, FaRedo, FaTwitter, FaFacebook, FaWhatsapp, FaCopy, FaRoute, FaCrown, FaChartLine } from 'react-icons/fa';
import { Link, useNavigate } from 'react-router-dom'; // Updated to useNavigate
import { Tooltip } from 'react-tooltip'; // You might need to install this package
import ToggleSwitch from '../../components/ToggleSwitch'; // Import the ToggleSwitch component
import { sendEvent } from '../../utils/analytics';
import { debounce } from 'lodash'; // Make sure to install lodash if not already installed
import { OpenStreetMapProvider } from 'leaflet-geosearch';
import { useAuth } from '../../contexts/AuthContext'; // Add this import
import { usePreferences } from '../../contexts/Preferences';
interface SidebarProps {
  resetRoute: () => void;
  currentStep: number;
  goToNextStep: () => void;
  goToPreviousStep: () => void;
  setWaypoints: React.Dispatch<React.SetStateAction<LatLng[]>>;
  onSurfaceChange: (surfaceType: string) => void;
  onDistanceChange: (distance: number) => void;
  onStartFromCurrentChange: (startFromCurrent: boolean) => void;
  onDrawingModeChange: (drawingMode: string) => void;
  startFromCurrent: boolean;
  drawingMode: string;
  distance: number;
  routeDistance: number;
  mapType: string;
  setMapType: (mapType: string) => void;
  route: L.LatLng[];  // Route is now an array of LatLng
  generateGPX: (route: L.LatLng[]) => void;
  undoWaypoint: () => void;
  redoWaypoint: () => void;
  handleFileUpload: (event: React.ChangeEvent<HTMLInputElement>) => void;
  currentLocation: LatLng | null;
  showElevation: boolean;
  toggleElevationDisplay: () => void;
  sidebarOpen: boolean;
  setSidebarOpen: React.Dispatch<React.SetStateAction<boolean>>;
  showPopularRoutes: boolean;
  togglePopularRoutes: () => void;
  isOffline: boolean;
  toggleOfflineMode: () => void;
  loadSavedGpxFile: (content: string) => void;
  savedGpxFiles: { [key: string]: string };
  saveRoute: (routeName: string) => Promise<string>;
  isKm: boolean;
  setIsKm: React.Dispatch<React.SetStateAction<boolean>>;
  showDistanceMarkers: boolean;
  handleDistanceMarkersToggle: () => void;
  loadedRouteName: string;
  showWaypoints: boolean;
  setShowWaypoints: React.Dispatch<React.SetStateAction<boolean>>;
  handleAddressSearch: (address: string) => void;
  searchAddress: string;
  setSearchAddress: React.Dispatch<React.SetStateAction<string>>;
  suggestions: string[];
  setSuggestions: React.Dispatch<React.SetStateAction<string[]>>;
  showSaveModal: boolean;
  setShowSaveModal: React.Dispatch<React.SetStateAction<boolean>>;
}

const SidebarContainer = styled.div<{ isOpen: boolean }>`
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: #f8f9fa;
  box-shadow: 0 -2px 10px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease-in-out;
  transform: translateY(${({ isOpen }) => (isOpen ? '0' : '100%')});
  max-height: 50vh; // Limit height on mobile
  display: flex;
  flex-direction: column;
  z-index: 999;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;

  @media (min-width: 768px) {
    width: 350px;
    left: 0;
    right: auto;
    top: 56px;
    bottom: 0;
    transform: translateX(${({ isOpen }) => (isOpen ? '0' : '-100%')});
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    max-height: calc(100vh - 56px);
  }
`;

const SidebarHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
  background-color: #f8f9fa;
  position: sticky;
  top: 0;
  z-index: 1;
`;

const SidebarContent = styled.div`
  padding: 0 20px 20px;
  overflow-y: auto;
  flex-grow: 1;
`;

const SidebarTitle = styled.h2`
  margin: 0;
  font-size: 1.5rem;
  color: #1e7d71;
`;

const CloseButton = styled.button`
  background: none;
  border: none;
  font-size: 1.5rem;
  color: #333333; // Darker color for better contrast
  cursor: pointer;
  padding: 5px;

  &:hover {
    color: #000000; // Even darker on hover
  }
`;

const SectionTitle = styled.h3`
  font-size: 1.2rem;
  color: #264653;
  margin-bottom: 10px;
`;

const Button = styled.button`
  width: 100%;
  padding: 12px;
  background-color: #2980b9; // Darker blue for better contrast
  color: white;
  border: none;
  border-radius: 25px;
  font-size: 1rem;
  font-weight: bold;
  cursor: pointer;
  margin-bottom: 12px;
  transition: all 0.3s ease;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  text-transform: uppercase;
  letter-spacing: 0.5px;

  &:hover {
    background-color: #1c638b; // Even darker on hover
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    transform: translateY(-2px);
  }

  &:active {
    transform: translateY(1px);
    box-shadow: 0 2px 3px rgba(0, 0, 0, 0.1);
  }
`;

const PrimaryButton = styled(Button)`
  background-color: #27ae60; // Darker green for better contrast

  &:hover {
    background-color: #1e8449; // Even darker on hover
  }

  &:disabled {
    background-color: #95a5a6; // Grayish color for disabled state
    cursor: not-allowed;
    opacity: 0.7;
    
    &:hover {
      background-color: #95a5a6; // Keep the same color on hover when disabled
      transform: none; // Remove any hover transform effects
    }
  }
`;

const SecondaryButton = styled(Button)`
  background-color: #c0392b; // Darker red for better contrast

  &:hover {
    background-color: #a93226; // Even darker on hover
  }
`;

const Select = styled.select`
  width: 100%;
  padding: 10px;
  margin-bottom: 10px;
  border: 1px solid #ddd;
  border-radius: 5px;
  font-size: 1rem;
`;

const Input = styled.input`
  width: calc(100% - 10px); // Subtract padding from width
  padding: 10px;
  margin-bottom: 10px;
  border: 1px solid #ddd;
  border-radius: 5px;
  font-size: 1rem;
  box-sizing: border-box; // Include padding in width calculation
`;

const ToggleSidebarButton = styled.button`
  position: fixed;
  background-color: #1e7d71; // Darker teal for better contrast
  color: white;
  border: none;
  border-radius: 5px;
  padding: 12px 20px;
  font-size: 1rem;
  cursor: pointer;
  z-index: 1000; // Set lower than navbar
  transition: background-color 0.3s, box-shadow 0.3s;
  display: flex;
  align-items: center;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);

  &:hover {
    background-color: #17635a; // Even darker on hover
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
  }

  svg {
    margin-right: 8px;
  }

  @media (min-width: 768px) {
    top: 86px; // 20px below the assumed 56px navbar height
    left: 20px;
  }

  @media (max-width: 767px) {
    bottom: 20px;
    left: 50%;
    transform: translateX(-50%);
    border-radius: 25px;
  }
`;

const ButtonGroup = styled.div`
  display: flex;
  gap: 10px;
  margin-bottom: 12px;
`;

const IconButton = styled(Button)`
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px;

  svg {
    margin-right: 5px;
  }
`;

const ShareModal = styled.div`
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: white;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  z-index: 1000;
`;

const ShareButton = styled(IconButton)`
  margin-bottom: 10px;
`;

const CloseModalButton = styled(Button)`
  margin-top: 10px;
`;

const PremiumBanner = styled.div`
  background-color: #f1c40f; // Slightly darker yellow for better contrast
  color: #000; // Black text for maximum contrast
  padding: 15px;
  border-radius: 10px;
  margin-bottom: 20px;
  text-align: center;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
`;

const PremiumButton = styled(PrimaryButton)`
  background-color: #f1c40f; // Matching the banner color
  color: #000; // Black text for maximum contrast
  
  &:hover {
    background-color: #d4ac0d; // Darker on hover
  }

  svg {
    margin-right: 8px;
  }
`;

const PremiumFeature = styled.div`
  background-color: #f0f0f0;
  border: 1px solid #ddd;
  border-radius: 5px;
  padding: 10px;
  margin-bottom: 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const FeatureIcon = styled.span`
  margin-right: 10px;
  color: #ffd700;
`;

const LockedIcon = styled.span`
  color: #888;
`;

const Modal = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
`;

const ModalContent = styled.div`
  background-color: white;
  padding: 20px;
  border-radius: 10px;
  width: 300px;
  max-width: 90vw; // Limit width on smaller screens
  box-sizing: border-box; // Include padding in width calculation
`;

const FileUploadButton = styled(PrimaryButton)`
  position: relative;
  overflow: hidden;
  margin-bottom: 12px; // Match the margin of other buttons
  width: 92%; // Ensure full width like other buttons
  display: block; // Change from inline to block
  text-align: center; // Center the text horizontally
  display: flex; // Use flexbox for vertical centering
  justify-content: center; // Center content horizontally
  align-items: center; // Center content vertically
  height: 20px; // Set a specific height to match other buttons (adjust as needed)
`;

const HiddenFileInput = styled.input`
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
  width: 100%;
  height: 100%;
  cursor: pointer;
`;

const EmbedCodeInput = styled.textarea`
  width: 100%;
  height: 100px;
  margin-bottom: 10px;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 5px;
  font-size: 0.9rem;
  resize: vertical;
`;

const DisabledButton = styled(Button)`
  opacity: 0.5;
  cursor: not-allowed;
`;

const Label = styled.label`
  display: block;
  margin-bottom: 5px;
  font-weight: bold;
`;

const SearchInput = styled.input`
  width: 90%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 16px;
`;

const SuggestionsList = styled.ul`
  list-style-type: none;
  padding: 0;
  margin: 0;
  background-color: white;
  border: 1px solid #ccc;
  border-top: none;
  border-radius: 0 0 4px 4px;
  max-height: 200px;
  overflow-y: auto;
`;

const SuggestionItem = styled.li`
  padding: 10px;
  cursor: pointer;
  &:hover {
    background-color: #f0f0f0;
  }
`;

const Sidebar: React.FC<SidebarProps> = ({
  resetRoute,
  currentStep,
  goToNextStep,
  goToPreviousStep,
  onDistanceChange,
  onDrawingModeChange,
  drawingMode,
  distance,
  routeDistance,
  onSurfaceChange,
  mapType,
  setMapType,
  route,
  generateGPX,
  undoWaypoint,
  redoWaypoint,
  handleFileUpload,
  setWaypoints,
  currentLocation,
  showElevation,
  toggleElevationDisplay,
  sidebarOpen,
  setSidebarOpen,
  showPopularRoutes,
  togglePopularRoutes,
  isOffline,
  toggleOfflineMode,
  loadSavedGpxFile,
  savedGpxFiles,
  saveRoute,
  isKm,
  setIsKm,
  showDistanceMarkers,
  handleDistanceMarkersToggle,
  loadedRouteName,
  showWaypoints,
  setShowWaypoints,
  handleAddressSearch,
  searchAddress,
  setSearchAddress,
  suggestions,
  setSuggestions,
  showSaveModal,
  setShowSaveModal,
}) => {
  const [showShareModal, setShowShareModal] = useState(false);
  const [shareableLink, setShareableLink] = useState('');
  // const [isPremium, setIsPremium] = useState(false);
  const [surfaceType, setSurfaceType] = useState('foot-walking');
  const [routeName, setRouteName] = useState('');
  const [embedCode, setEmbedCode] = useState('');
  const [showEmbedModal, setShowEmbedModal] = useState(false);
  const [unconfirmedDistance, setUnconfirmedDistance] = useState(distance);
  const [debouncedSearchTerm, setDebouncedSearchTerm] = useState('');

  const provider = useRef(new OpenStreetMapProvider());

  const navigate = useNavigate(); // Updated to useNavigate

  const { user, isPremium, isTrainingPremium } = useAuth();
  const { updatePreferences } = usePreferences();

  const handleDistanceUnitToggle = async () => {
    if (!user?.username) {
      setIsKm(!isKm);
      return;
    }
    
    try {
      await updatePreferences(user.username, {
        isKm: !isKm,
        isPremium: isPremium,
        isTrainingPremium: isTrainingPremium
      });
      setIsKm(!isKm);
    } catch (error) {
      console.error('Error updating preferences:', error);
      // No need to revert since we haven't changed the state yet
    }
  };

  const handleDistanceInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setUnconfirmedDistance(Number(e.target.value));
  };

  const handleSetDistance = () => {
    onDistanceChange(unconfirmedDistance);

    // Close the sidebar on mobile devices
    if (window.innerWidth < 768) {
      setSidebarOpen(false);
    }
  };

  const onDBRClick = () => {
    onDrawingModeChange('distance');
    goToNextStep();
  };

  const onFDClick = () => {
    onDrawingModeChange('free');
    goToNextStep();
  };

  const handleSurfaceChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const newSurfaceType = e.target.value;
    setSurfaceType(newSurfaceType);
    onSurfaceChange(newSurfaceType);
  };

  const handleMapTypeChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setMapType(e.target.value);
  };

  const handleShare = () => {
    const urlParams = new URLSearchParams(window.location.search);
    const routeId = urlParams.get('loadRoute');

    if (routeId) {
      const link = `${window.location.origin}/running-route-planner?loadRoute=${routeId}`
      setShareableLink(link);
      setShowShareModal(true);
    }
  };

  const shareToTwitter = () => {
    const twitterUrl = `https://twitter.com/intent/tweet?text=Check%20out%20my%20route!&url=${encodeURIComponent(shareableLink)}`;
    window.open(twitterUrl, '_blank');
  };

  const shareToFacebook = () => {
    const facebookUrl = `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(shareableLink)}`;
    window.open(facebookUrl, '_blank');
  };

  const shareToWhatsApp = () => {
    const whatsappUrl = `https://wa.me/?text=${encodeURIComponent(`Check out my route! ${shareableLink}`)}`;
    window.open(whatsappUrl, '_blank');
  };

  const copyToClipboard = () => {
    navigator.clipboard.writeText(shareableLink).then(() => {
      alert('Link copied to clipboard!');
    }).catch(err => {
      console.error('Failed to copy link:', err);
    });
  };

  const handleDownloadGPX = () => {
    sendEvent('download_gpx', { route_name: routeName });
    generateGPX(route);  // Pass the retrieved array to generateGPX
  };

  const handleUseCurrentLocation = () => {
    sendEvent('use_current_location', { step: 'start_point' });
    if (currentLocation) {
      setWaypoints([currentLocation]);
      goToNextStep();
    }
  };

  const handleSubscribe = async () => {
    if (!user) {
      alert('You must be signed in to subscribe.');
      navigate('/login');
      return;
    }
    try {
      const response = await fetch('https://c3xzkliim3.execute-api.eu-north-1.amazonaws.com/stripepaymentrn', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          email: user.email || '', // Use email from AuthContext
          env: process.env.REACT_APP_ENV || '',
        }),
      });

      if (!response.ok) {
        throw new Error('Failed to create checkout session');
      }

      const { checkoutUrl, sessionId, customerId } = await response.json();

      if (!checkoutUrl) {
        throw new Error('No redirect URL received from the server');
      }

      // Store sessionId and customerId in localStorage for later use
      localStorage.setItem('stripeSessionId', sessionId);
      localStorage.setItem('stripeCustomerId', customerId);

      // Redirect to the Stripe Checkout page
      window.location.href = checkoutUrl;
    } catch (error) {
      console.error('Subscription error:', error);
      alert('An error occurred while processing your subscription. Please try again.');
    }
  };

  const handlePremiumFeature = (feature: string) => {
    sendEvent('premium_feature_click', { feature: feature, is_premium: isPremium });
    if (isPremium) {
      switch (feature) {
        case 'elevation':
          toggleElevationDisplay();
          break;
        case 'popularRoutes':
          togglePopularRoutes();
          break;
      }
    } else {
      alert('This is a premium feature. Please upgrade to access it.');
    }
  };

  const handleSaveRouteClick = () => {
    sendEvent('save_route', { route_name: routeName });
    if (!user) {
      alert('You must be signed in to save a route.');
      // Store route data directly in localStorage
      saveRouteToStorage(route);
      navigate('/login', { state: { from: '/running-route-planner' } });
      return;
    }
    setShowSaveModal(true);
  };

  const handleSaveRoute = () => {
    if (routeName.trim() !== '') {
      saveRoute(routeName);
      setShowSaveModal(false);
    } else {
      alert('Please enter a name for your route.');
    }
  };

  const handleEmbed = useCallback(async () => {
    if (route.length === 0) {
      alert('Please create a route before embedding.');
      return;
    }

    const urlParams = new URLSearchParams(window.location.search);
    const routeId = urlParams.get('loadRoute');

    if (!routeId) {
      setShowSaveModal(true);
      return;
    }

    try {
      const embedCode = `<iframe src="${window.location.origin}/embed-route/${routeId}" width="600" height="450" style="border:0;" allowfullscreen="" loading="lazy"></iframe>`;
      setEmbedCode(embedCode);
      setShowEmbedModal(true);
    } catch (error) {
      console.error('Error generating embed code:', error);
      alert('An error occurred while generating the embed code. Please try again.');
    }
  }, [route, routeName, saveRoute]);

  const copyEmbedCode = () => {
    navigator.clipboard.writeText(embedCode).then(() => {
      alert('Embed code copied to clipboard!');
    }).catch(err => {
      console.error('Failed to copy embed code:', err);
    });
  };

  const handleWaypointToggle = () => {
    setShowWaypoints(!showWaypoints);
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setSearchAddress(value);
    setDebouncedSearchTerm(value);
  };

  const handleSuggestionClick = (suggestion: string) => {
    handleAddressSearch(suggestion);
  };

  const debouncedSearch = useCallback(
    debounce((term) => {
      if (term.length > 2) {
        provider.current.search({ query: term })
          .then((results) => {
            setSuggestions(results.slice(0, 5).map(result => result.label));
          })
          .catch((error) => {
            console.error('Error fetching suggestions:', error);
          });
      } else {
        setSuggestions([]);
      }
    }, 300), // 300ms delay
    []
  );

  // Use an effect to trigger the debounced search
  useEffect(() => {
    debouncedSearch(debouncedSearchTerm);
  }, [debouncedSearchTerm, debouncedSearch]);

  // Store the route
  const saveRouteToStorage = (routeData: Array<{lat: number, lng: number}>) => {
    localStorage.setItem('pendingRoute', JSON.stringify(routeData));
  };

  // Retrieve the route
  // const getPendingRoute = (): Array<{lat: number, lng: number}> | null => {
  //   const savedRoute = localStorage.getItem('pendingRoute');
  //   if (savedRoute) {
  //     try {
  //       const parsedRoute = JSON.parse(savedRoute);
  //       // Clear storage after retrieving
  //       localStorage.removeItem('pendingRoute');
  //       return parsedRoute;
  //     } catch (error) {
  //       console.error('Error parsing stored route:', error);
  //       localStorage.removeItem('pendingRoute');
  //       return null;
  //     }
  //   }
  //   return null;
  // };

  // // Example usage:
  // saveRouteToStorage(route);
  // const retrievedRoute = getPendingRoute();

  return (
    <>
      <SidebarContainer isOpen={sidebarOpen}>
        <SidebarHeader>
          <SidebarTitle>Running Route Planner</SidebarTitle>
          <CloseButton
            onClick={() => setSidebarOpen(false)}
            aria-label="Close Sidebar"
          >
            &times;
          </CloseButton>
        </SidebarHeader>
        <SidebarContent>

          {currentStep === 1 && (
            <>
              <p>Welcome to our interactive running route planner. Create custom routes, save your favorite paths, and discover popular running spots in your area. Whether you're training for a marathon or just enjoying a casual jog, our tool helps you plan the perfect run.</p>
              <Label htmlFor="map-type">Map Type</Label>
              <Select id="map-type" value={mapType} onChange={handleMapTypeChange}>
                <option value="standard">Standard</option>
                <option value="satellite">Satellite</option>
              </Select>
              <SectionTitle>Select Start Point</SectionTitle>
              <p>Click on the map to set the starting point of your route.</p>
              <p>Or: </p>
              <PrimaryButton 
                disabled={!currentLocation} 
                onClick={handleUseCurrentLocation}
                data-tooltip-id={!currentLocation ? "location-tooltip" : undefined}
                data-tooltip-content={!currentLocation ? "Please enable location services to use this feature" : undefined}
              >
                Use Current Location
              </PrimaryButton>
              <Tooltip id="location-tooltip" />
              <p>Or Enter Start Address:</p>
              <SearchInput
                type="text"
                placeholder="Enter start address"
                value={searchAddress}
                onChange={handleInputChange}
                onKeyPress={(e) => {
                  if (e.key === 'Enter') {
                    handleAddressSearch(searchAddress);
                  }
                }}
              />
              {suggestions.length > 0 && (
                <SuggestionsList>
                  {suggestions.map((suggestion, index) => (
                    <SuggestionItem
                      key={index}
                      onClick={() => handleSuggestionClick(suggestion)}
                    >
                      {suggestion}
                    </SuggestionItem>
                  ))}
                </SuggestionsList>
              )}
              <SectionTitle>Alternatively:</SectionTitle>
              <FileUploadButton as="label">
                Upload GPX File
                <HiddenFileInput type="file" accept=".gpx" onChange={handleFileUpload} />
              </FileUploadButton>
            </>
          )}

          {currentStep === 2 && (
            <>
              <SectionTitle>Choose Routing Mode</SectionTitle>
              <PrimaryButton onClick={onDBRClick}>Distance-Based Routing</PrimaryButton>
              <PrimaryButton onClick={onFDClick}>Free Drawing</PrimaryButton>
              <SectionTitle>OR</SectionTitle>
              <SecondaryButton onClick={resetRoute}>Reset Starting Point</SecondaryButton>
            </>
          )}

          {currentStep === 3 && (
            <>
              <SectionTitle>{drawingMode === 'distance' ? 'Set Distance' : 'Add Waypoints'}</SectionTitle>
              <SectionTitle>Distance Unit</SectionTitle>
              <p>You can save user preferences in <Link to="/account">your account</Link>.</p>
              <ToggleSwitch id="distance-unit-toggle" isChecked={isKm} onToggle={handleDistanceUnitToggle} leftLabel="Miles" rightLabel="Kilometers" />
              <SectionTitle>Distance Markers</SectionTitle>
              <ToggleSwitch id="distance-markers-toggle" isChecked={showDistanceMarkers} onToggle={handleDistanceMarkersToggle} leftLabel="Hide" rightLabel="Show" />
              {drawingMode === 'free' && (
                <>
                  <SectionTitle>Waypoint Markers</SectionTitle>
                  <ToggleSwitch id="waypoint-markers-toggle" isChecked={showWaypoints} onToggle={handleWaypointToggle} leftLabel="Hide" rightLabel="Show" />
                </>
              )}
              <br></br>
              <Label htmlFor="surface-type">Surface Type</Label>
              <Select id="surface-type" value={surfaceType} onChange={handleSurfaceChange}>
                <option value="foot-walking">Road</option>
                <option value="foot-hiking">Trail</option>
              </Select>
              {drawingMode === 'distance' && (
                <>
                  <Input
                    type="number"
                    value={unconfirmedDistance}
                    onChange={handleDistanceInputChange}
                    placeholder={`Distance (${isKm ? 'km' : 'miles'})`}
                  />
                  <Button onClick={handleSetDistance}>Create Route</Button>
                </>
              )}
              <Button onClick={goToPreviousStep}>Back</Button>
              {drawingMode === 'free' && (
                <p>Current Route Distance: {isKm ? routeDistance.toFixed(2) : (routeDistance * 0.621371).toFixed(2)} {isKm ? 'km' : 'miles'}</p>
              )}
              {drawingMode === 'free' && (
                <ButtonGroup>
                  <IconButton
                    onClick={undoWaypoint}
                    aria-label="Undo Waypoint"
                  >
                    <FaUndo /> Undo
                  </IconButton>
                  <IconButton
                    onClick={redoWaypoint}
                    aria-label="Redo Waypoint"
                  >
                    <FaRedo /> Redo
                  </IconButton>
                </ButtonGroup>
              )}
              <PremiumFeature onClick={() => handlePremiumFeature('elevation')}>
                <div>
                  <FeatureIcon><FaChartLine /></FeatureIcon>
                  Show Elevation Data
                </div>
                {isPremium ? (
                  <Button>{showElevation ? 'Hide' : 'Show'}</Button>
                ) : (
                  <LockedIcon aria-label="Premium Feature Locked"><FaCrown /></LockedIcon>
                )}
              </PremiumFeature>
              <PremiumFeature onClick={() => handlePremiumFeature('popularRoutes')}>
                <div>
                  <FeatureIcon><FaRoute /></FeatureIcon>
                  Show Popular Routes
                </div>
                {isPremium ? (
                  <Button>{showPopularRoutes ? 'Hide' : 'Show'}</Button>
                ) : (
                  <LockedIcon aria-label="Premium Feature Locked"><FaCrown /></LockedIcon>
                )}
              </PremiumFeature>
              <PrimaryButton onClick={handleSaveRouteClick}>Save Route</PrimaryButton>
              <PrimaryButton onClick={handleDownloadGPX}>Download GPX</PrimaryButton>
              {routeName ? (
                <PrimaryButton onClick={handleShare}>Share Route</PrimaryButton>
              ) : (
                <DisabledButton
                  data-tooltip-id="share-tooltip"
                  data-tooltip-content="You must save the route before sharing"
                  aria-disabled="true"
                  aria-label="Share Route (disabled, please save the route first)"
                >
                  Share Route
                </DisabledButton>
              )}
              <Tooltip id="share-tooltip" />

              {routeName ? (
                <PrimaryButton onClick={handleEmbed}>Embed Route</PrimaryButton>
              ) : (
                <DisabledButton
                  data-tooltip-id="embed-tooltip"
                  data-tooltip-content="You must save the route before embedding"
                  aria-disabled="true"
                  aria-label="Embed Route (disabled, please save the route first)"
                >
                  Embed Route
                </DisabledButton>
              )}
              <Tooltip id="embed-tooltip" />
              <Button onClick={resetRoute}>Create New Route</Button>
            </>
          )}

          {currentStep === 4 && (
            <>
              <SectionTitle>Route Generated</SectionTitle>
              <p>Your route has been generated. You can view it on the map.</p>
              <Button onClick={goToPreviousStep}>Back</Button>
            </>
          )}

          {currentStep === 5 && (
            <>
              <SectionTitle>File Uploaded</SectionTitle>
              <p>Your route has been generated. You can view it on the map.</p>
              <FileUploadButton as="label">
                Upload Another GPX File
                <HiddenFileInput type="file" accept=".gpx" onChange={handleFileUpload} />
              </FileUploadButton>
              <PremiumFeature onClick={() => handlePremiumFeature('elevation')}>
                <div>
                  <FeatureIcon><FaChartLine /></FeatureIcon>
                  Show Elevation Data
                </div>
                {isPremium ? (
                  <Button>{showElevation ? 'Hide' : 'Show'}</Button>
                ) : (
                  <LockedIcon aria-label="Premium Feature Locked"><FaCrown /></LockedIcon>
                )}
              </PremiumFeature>
              <PrimaryButton onClick={handleSaveRouteClick}>Save Route</PrimaryButton>
              <PrimaryButton onClick={handleEmbed}>Embed Route</PrimaryButton>
              <PrimaryButton onClick={handleShare}>Share Route</PrimaryButton>
              <Button onClick={resetRoute}>Create New Route</Button>
            </>
          )}

          {currentStep === 6 && (
            <>
              <SectionTitle>{loadedRouteName}</SectionTitle>
              <SectionTitle>Distance Unit</SectionTitle>
              <p>You can save user preferences in <Link to="/account">your account</Link>.</p>
              <ToggleSwitch id="distance-unit-toggle" isChecked={isKm} onToggle={handleDistanceUnitToggle} leftLabel="Miles" rightLabel="Kilometers" />
              <SectionTitle>Distance Markers</SectionTitle>
              <ToggleSwitch id="distance-markers-toggle" isChecked={showDistanceMarkers} onToggle={handleDistanceMarkersToggle} leftLabel="Hide" rightLabel="Show" />
              <br></br>
              <PremiumFeature onClick={() => handlePremiumFeature('elevation')}>
                <div>
                  <FeatureIcon><FaChartLine /></FeatureIcon>
                  Show Elevation Data
                </div>
                {isPremium ? (
                  <Button>{showElevation ? 'Hide' : 'Show'}</Button>
                ) : (
                  <LockedIcon aria-label="Premium Feature Locked"><FaCrown /></LockedIcon>
                )}
              </PremiumFeature>
              <PrimaryButton onClick={handleEmbed}>Embed Route</PrimaryButton>
              <PrimaryButton onClick={handleShare}>Share Route</PrimaryButton>
              <Button onClick={handleDownloadGPX}>Download GPX</Button>
              <Button onClick={resetRoute}>Create New Route</Button>
            </>
          )}

          <Link to="/saved-routes">
            <Button>View Saved Routes</Button>
          </Link>

          {!isPremium && (
            <>
              <PremiumBanner>
                <h3>Unlock Premium Features!</h3>
                <p>Upgrade to access elevation data, popular routes, and more!</p>
              </PremiumBanner>
              <PremiumButton onClick={handleSubscribe}>
                <FaCrown /> Become a Premium User
              </PremiumButton>
            </>
          )}

          {isPremium && (
            <PremiumBanner>
              <h3>Premium User</h3>
              <p>Thank you for your support! Enjoy all premium features.</p>
            </PremiumBanner>
          )}

        </SidebarContent>
      </SidebarContainer>
      {!sidebarOpen && (
        <ToggleSidebarButton onClick={() => setSidebarOpen(true)}>
          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" viewBox="0 0 16 16">
            <path
              fillRule="evenodd"
              d="M2.5 12a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 
              1-.5-.5zm0-4a.5.5 0 0 1 
              .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 
              1-.5-.5zm0-4a.5.5 0 0 1 .5-.5h10a.5.5 
              0 0 1 0 1H3a.5.5 0 0 1-.5-.5z"
            />
          </svg>
          Open Planner
        </ToggleSidebarButton>
      )}

      {showShareModal && (
        <ShareModal>
          <h3>Share your route</h3>
          <ShareButton onClick={shareToTwitter}>
            <FaTwitter /> Share on Twitter
          </ShareButton>
          <ShareButton onClick={shareToFacebook}>
            <FaFacebook /> Share on Facebook
          </ShareButton>
          <ShareButton onClick={shareToWhatsApp}>
            <FaWhatsapp /> Share on WhatsApp
          </ShareButton>
          <ShareButton onClick={copyToClipboard}>
            <FaCopy /> Copy Link
          </ShareButton>
          <CloseModalButton onClick={() => setShowShareModal(false)}>Close</CloseModalButton>
        </ShareModal>
      )}

      {showSaveModal && (
        <Modal>
          <ModalContent>
            <h3>Save Route</h3>
            <Input
              type="text"
              value={routeName}
              onChange={(e) => setRouteName(e.target.value)}
              placeholder="Enter route name"
            />
            <ButtonGroup>
              <PrimaryButton onClick={handleSaveRoute}>Save</PrimaryButton>
              <SecondaryButton onClick={() => { setRouteName(''); setShowSaveModal(false) }}>Cancel</SecondaryButton>
            </ButtonGroup>
          </ModalContent>
        </Modal>
      )}

      {showEmbedModal && (
        <Modal>
          <ModalContent>
            <h3>Embed Route</h3>
            <p>Copy and paste this code to embed your route:</p>
            <EmbedCodeInput value={embedCode} readOnly />
            <ButtonGroup>
              <PrimaryButton onClick={copyEmbedCode}>Copy Code</PrimaryButton>
              <SecondaryButton onClick={() => setShowEmbedModal(false)}>Close</SecondaryButton>
            </ButtonGroup>
          </ModalContent>
        </Modal>
      )}
    </>
  );
};

export default Sidebar;
