// AuthContext.tsx
import React, { createContext, useState, useContext, useEffect, ReactNode } from 'react';
import { getCurrentUser, fetchUserAttributes } from 'aws-amplify/auth';
import { usePreferences } from './Preferences';

type AuthContextType = {
  user: { userId: string; username: string; email: string; isPremium: boolean; isTrainingPremium: boolean } | null;
  setUser: React.Dispatch<React.SetStateAction<{ userId: string; username: string; email: string; isPremium: boolean; isTrainingPremium: boolean } | null>>;
  isPremium: boolean;
  isTrainingPremium: boolean;
};

type AuthProviderProps = {
    children: ReactNode;
  };
  
  const AuthContext = createContext<AuthContextType | undefined>(undefined);
  
  export const AuthProvider: React.FC<AuthProviderProps> = ({ children }) => {
  const [user, setUser] = useState<{ userId: string; username: string; email: string; isPremium: boolean; isTrainingPremium: boolean } | null>(null);
  const [isPremium, setIsPremium] = useState(false);
  const [isTrainingPremium, setIsTrainingPremium] = useState(false);
  const { preferences, fetchPreferences } = usePreferences();
  useEffect(() => {
    const fetchUser = async () => {
      try {
        const currentUser = await getCurrentUser();
        const currentUserAttributes = await fetchUserAttributes();
        if (currentUser) {
          await fetchPreferences(currentUser.username);
          setUser({
            userId: currentUser.userId,
            username: currentUser.username,
            email: currentUserAttributes.email || '',
            isPremium: preferences.isPremium,
            isTrainingPremium: preferences.isTrainingPremium
          });
          setIsPremium(preferences.isPremium);
          setIsTrainingPremium(preferences.isTrainingPremium);
        }
      } catch (error) {
        console.error('Error fetching user:', error);
      }
    };
    fetchUser();
  }, [preferences.isPremium]);

  return (
    <AuthContext.Provider value={{ user, setUser, isPremium, isTrainingPremium }}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => {
  const context = useContext(AuthContext);
  if (context === undefined) {
    throw new Error('useAuth must be used within an AuthProvider');
  }
  return context;
};
