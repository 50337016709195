import React, { useState, useEffect } from 'react';
import styled from '@emotion/styled';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';
import axios from 'axios';

const CalculatorContainer = styled.div`
  flex: 1;
  background-color: #f0f4f8;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  padding: 20px;

  @media (max-width: 1024px) {
    width: 100%;
  }
`;

const Form = styled.form`
  display: flex;
  flex-direction: column;
  gap: 15px;
`;

const Input = styled.input`
  padding: 10px;
  border: 1px solid #ced4da;
  border-radius: 5px;
  font-size: 16px;
`;

const Button = styled.button`
  padding: 10px 15px;
  background-color: #2a9d8f;
  color: white;
  border: none;
  border-radius: 5px;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: #238b7e;
  }
`;

const ResultsContainer = styled.div`
  margin-top: 20px;
`;

// Add this new function to determine zone colors
const getZoneColor = (zoneName: string): string => {
  switch (zoneName) {
    case 'Zone 1 (Very Light)': return '#4dff4d'; // Light Green
    case 'Zone 2 (Light)': return '#1aa3ff';      // Light Blue
    case 'Zone 3 (Moderate)': return '#ffff4d';   // Yellow
    case 'Zone 4 (Hard)': return '#ff944d';       // Orange
    case 'Zone 5 (Maximum)': return '#ff4d4d';    // Red
    default: return '#000000';                    // Black (fallback)
  }
};

// Update the ZoneItem styled component
const ZoneItem = styled.div<{ color: string }>`
  display: flex;
  justify-content: space-between;
  padding: 10px;
  border-bottom: 1px solid #ced4da;
  background-color: ${props => props.color};
  color: ${props => ['#4dff4d', '#ffff4d'].includes(props.color) ? '#000' : '#fff'}; // Black text for light green and yellow backgrounds
  border-radius: 5px;
  margin-bottom: 5px;

  &:last-child {
    border-bottom: none;
  }
`;

const ContentWrapper = styled.div`
  display: flex;
  gap: 30px;

  @media (max-width: 1200px) {
    flex-direction: column;
  }
`;

const MainContent = styled.div`
  flex: 2;
  order: 2;

  @media (max-width: 1200px) {
    order: 1;
  }
`;

const LeftSidebar = styled.div`
  width: 20%;
  order: 1;

  @media (max-width: 1200px) {
    width: 100%;
    max-width: 600px;
    order: 3;
  }
`;

const RightSidebar = styled.div`
  width: 20%;
  order: 3;

  @media (max-width: 1200px) {
    width: 100%;
    order: 2;
  }
`;

const RelatedBlogsContainer = styled.div`
  background-color: #ffffff;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  padding: 20px;
`;

const RelatedBlogsHeader = styled.h2`
  font-size: 1.5rem;
  color: #264653;
  margin-bottom: 20px;
  text-align: center;
  `;

const RelatedBlogItem = styled(Link)`
  display: flex;
  align-items: center;
  margin-bottom: 20px;
  padding: 10px;
  border-bottom: 1px solid #e0e0e0;
  text-decoration: none;
  color: inherit;
  transition: background-color 0.3s ease;

  &:last-child {
    margin-bottom: 0;
    border-bottom: none;
  }

  &:hover {
    background-color: #f0f4f8;
  }
`;

const RelatedBlogThumbnail = styled.img`
  width: 80px;
  height: 80px;
  object-fit: cover;
  border-radius: 5px;
  margin-right: 15px;
`;

const RelatedBlogInfo = styled.div`
  flex: 1;
`;

const RelatedBlogTitle = styled.h3`
  font-size: 1rem;
  color: #2a9d8f;
  margin-bottom: 5px;
  transition: text-decoration 0.3s ease;

  &:hover {
    text-decoration: underline;
  }
`;

const RelatedBlogTopic = styled(Link)`
  display: inline-block;
  background-color: #e0f7fa;
  color: #006064;
  padding: 3px 8px;
  border-radius: 12px;
  font-size: 0.7rem;
  margin-bottom: 5px;
  text-decoration: none;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: #b2ebf2;
  }
`;

const HeartRateZoneCalculator: React.FC = () => {
  const [age, setAge] = useState<string>('');
  const [zones, setZones] = useState<{ name: string; range: string }[]>([]);
  const [relatedBlogs, setRelatedBlogs] = useState<any[]>([]);

  // Add useEffect to fetch blogs when component mounts
  useEffect(() => {
    const fetchRelatedBlogs = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_BLOGS_API_BASE_URL}/getAllBlogs`);
        const parsedData = JSON.parse(response.data.body);
        const blogs = parsedData.blog_posts
          .filter((post: any) => post.tags && post.tags.includes('Heart Rate Training'))
          .slice(0, 3)
          .map((post: any) => ({
            slug: post.slug,
            title: post.title,
            topic: post.topic,
            image: post.image,
          }));
        setRelatedBlogs(blogs);
      } catch (error) {
        console.error('Error fetching related blogs:', error);
        setRelatedBlogs([]);
      }
    };

    fetchRelatedBlogs();
  }, []);

  const calculateZones = (e: React.FormEvent) => {
    e.preventDefault();
    const ageNum = parseInt(age);

    if (isNaN(ageNum)) {
      alert('Please enter a valid number for age.');
      return;
    }

    const maxHR = 220 - ageNum;

    const calculateZoneHR = (percentage: number) => 
      Math.round(maxHR * percentage);

    const newZones = [
      { name: 'Zone 1 (Very Light)', range: `${calculateZoneHR(0.5)} - ${calculateZoneHR(0.6)}` },
      { name: 'Zone 2 (Light)', range: `${calculateZoneHR(0.6)} - ${calculateZoneHR(0.7)}` },
      { name: 'Zone 3 (Moderate)', range: `${calculateZoneHR(0.7)} - ${calculateZoneHR(0.8)}` },
      { name: 'Zone 4 (Hard)', range: `${calculateZoneHR(0.8)} - ${calculateZoneHR(0.9)}` },
      { name: 'Zone 5 (Maximum)', range: `${calculateZoneHR(0.9)} - ${maxHR}` },
    ];

    setZones(newZones);
  };

  return (
    <>
      <Helmet>
        <title>Heart Rate Zone Calculator | Fitness Tools</title>
        <meta name="description" content="Calculate your heart rate zones based on your age. Optimize your workouts and improve your cardiovascular fitness with our free heart rate zone calculator." />
        <meta name="keywords" content="heart rate zones, fitness calculator, cardiovascular training, workout intensity, age-based heart rate" />
        <meta property="og:title" content="Heart Rate Zone Calculator" />
        <meta property="og:description" content="Calculate your personalized heart rate zones for optimal workout intensity." />
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://airunningtraining.com/heart-rate-zone-calculator" />
        <link rel="canonical" href="https://airunningtraining.com/heart-rate-zone-calculator" />
      </Helmet>
      <ContentWrapper>
        <LeftSidebar>
          {/* Add content for left sidebar if needed */}
        </LeftSidebar>
        <MainContent>
          <CalculatorContainer>
            <header>
              <h1>Heart Rate Zone Calculator</h1>
            </header>
            <main>
              <section>
                <h2>Calculate Your Heart Rate Zones</h2>
                <p>Enter your age to determine your personalized heart rate zones for optimal training intensity.</p>
                <Form onSubmit={calculateZones}>
                  <label htmlFor="age-input">Age:</label>
                  <Input
                    id="age-input"
                    type="number"
                    placeholder="Enter your age"
                    value={age}
                    onChange={(e) => setAge(e.target.value)}
                    required
                    aria-label="Enter your age"
                  />
                  <Button type="submit">Calculate Zones</Button>
                </Form>
              </section>
              {zones.length > 0 && (
                <ResultsContainer>
                  <h3>Your Heart Rate Zones:</h3>
                  {zones.map((zone, index) => (
                    <ZoneItem key={index} color={getZoneColor(zone.name)}>
                      <span>{zone.name}</span>
                      <span>{zone.range} bpm</span>
                    </ZoneItem>
                  ))}
                </ResultsContainer>
              )}
              <section>
                <h2>Understanding Heart Rate Zones</h2>
                <p>Heart rate zones help you optimize your workouts by targeting specific intensities. Each zone serves a different purpose in your fitness journey:</p>
                <ul>
                  <li><strong>Zone 1 (Very Light):</strong> Warm-up and recovery</li>
                  <li><strong>Zone 2 (Light):</strong> Improves basic endurance and fat burning</li>
                  <li><strong>Zone 3 (Moderate):</strong> Improves aerobic fitness</li>
                  <li><strong>Zone 4 (Hard):</strong> Increases maximum performance capacity</li>
                  <li><strong>Zone 5 (Maximum):</strong> Develops maximum performance and speed</li>
                </ul>
              </section>
            </main>
            <footer>
              <p>Disclaimer: This calculator provides estimates based on general formulas. Consult with a healthcare professional for personalized advice.</p>
            </footer>
          </CalculatorContainer>
        </MainContent>
        <RightSidebar>
          <RelatedBlogsContainer>
            <RelatedBlogsHeader>Related Blogs</RelatedBlogsHeader>
            {relatedBlogs.map(blog => (
              <RelatedBlogItem 
                key={blog.slug} 
                to={`/blog/${blog.topic.toLowerCase().replace(/\s+/g, '-')}/${blog.slug}`}
              >
                <RelatedBlogThumbnail 
                  src={`https://public-blog-images-mlp.s3.amazonaws.com/${blog.slug}.png`}
                  alt={blog.title} 
                />
                <RelatedBlogInfo>
                  <RelatedBlogTitle className="related-blog-title">{blog.title}</RelatedBlogTitle>
                  <RelatedBlogTopic 
                    to={`/blogs/${blog.topic.toLowerCase().replace(/\s+/g, '-')}`}
                  >
                    {blog.topic}
                  </RelatedBlogTopic>
                </RelatedBlogInfo>
              </RelatedBlogItem>
            ))}
          </RelatedBlogsContainer>
        </RightSidebar>
      </ContentWrapper>
    </>
  );
};

export default HeartRateZoneCalculator;
