import React, { useState, useEffect, useRef } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import styled from '@emotion/styled';
import axios from 'axios';
import { useAuth } from '../../contexts/AuthContext';
import RunTypes from './runTypes';

interface TrainingDay {
  day: string;
  workout: string;
  week?: number;
}

interface LocationState {
  goal: string;
  duration: string;
  targetTime: string;
  isKm: boolean;
  trainingDays: string[];
  trainingPaces: any;
}

const Container = styled.div`
  max-width: 900px;
  margin: 0 auto;
  padding: 0 16px;
`;

const Box = styled.div<BoxProps>`
  margin-top: ${props => props.mt || 0}px;
  margin-bottom: ${props => props.mb || 0}px;
  padding: ${props => props.p || 0}px;
  display: ${props => props.display || 'block'};
  flex-direction: ${props => props.flexDirection || 'row'};
  justify-content: ${props => props.justifyContent || 'flex-start'};
  align-items: ${props => props.alignItems || 'flex-start'};
  gap: ${props => props.gap || 0}px;
  
  @media (max-width: 768px) {
    align-items: flex-start;
    justify-content: flex-start;
    text-align: left;
    width: 95vw;
  }
`;

const Typography = styled.h1`
  text-align: center;
  margin-bottom: 16px;
`;

interface BoxProps {
  mt?: number;
  mb?: number;
  p?: number;
  display?: string;
  flexDirection?: string;
  justifyContent?: string;
  alignItems?: string;
  gap?: number;
}

const Table = styled.table`
  width: 100%;
  border-collapse: collapse;
  margin-top: 24px;
`;

const Th = styled.th`
  padding: 12px;
  background-color: #f5f5f5;
  border: 1px solid #ddd;
  text-align: left;
`;

const Td = styled.td`
  padding: 12px;
  border: 1px solid #ddd;
`;

const SaveButton = styled.button`
  padding: 12px 24px;
  background-color: #4CAF50;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 16px;
  
  &:hover {
    background-color: #45a049;
  }

  &:disabled {
    background-color: #cccccc;
    cursor: not-allowed;
    opacity: 0.7;
    
    &:hover {
      background-color: #cccccc;
    }
  }
`;

const ButtonContainer = styled(Box)`
  display: flex;
  gap: 16px;
  justify-content: center;
  
  @media (max-width: 768px) {
    justify-content: flex-start;
    flex-direction: column;
    width: 95vw;
    
    button {
      width: 95vw;
    }
  }
`;

const ViewButton = styled(SaveButton)`
  background-color: #2196F3;
  
  &:hover {
    background-color: #1976D2;
  }
`;

const Modal = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
`;

const ModalContent = styled.div`
  background-color: white;
  padding: 24px;
  border-radius: 8px;
  width: 400px;
`;

const Input = styled.input`
  width: 100%;
  padding: 8px;
  margin: 16px 0;
  border: 1px solid #ddd;
  border-radius: 4px;
`;

const LoadingSpinner = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 20px 0;
  
  .spinner {
    border: 4px solid #f3f3f3;
    border-top: 4px solid #3498db;
    border-radius: 50%;
    width: 40px;
    height: 40px;
    animation: spin 1s linear infinite;
  }

  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }
`;

const PacesTable = styled(Table)`
  margin-bottom: 24px;
  width: auto;
  min-width: 300px;
  
  @media (max-width: 768px) {
    margin: 0 0 24px 0;
    width: 95vw;
  }
`;

const LinkText = styled.span`
  color: #2196F3;
  text-decoration: underline;
  cursor: pointer;
  
  &:hover {
    color: #1976D2;
  }
`;

const RunTypesContainer = styled(Box)`
  width: 95vw;
`;

const ViewTrainingPlan = () => {
  const [trainingPlan, setTrainingPlan] = useState<TrainingDay[]>([]);
  const location = useLocation();
  const navigate = useNavigate();
  const { user } = useAuth();
  const { goal, duration, targetTime, isKm, trainingDays, trainingPaces } = location.state as LocationState;
  const [showModal, setShowModal] = useState(false);
  const [planName, setPlanName] = useState('');
  const [isSaving, setIsSaving] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const runTypesRef = useRef<HTMLDivElement>(null);

  console.log('trainingPaces', trainingPaces);

  const parsedPaces = React.useMemo(() => {
    if (typeof trainingPaces === 'object' && trainingPaces !== null) {
      const { trainingPaces: paceString } = trainingPaces as { trainingPaces: string };
      if (typeof paceString === 'string') {
        return paceString.split(',').reduce((acc, pace) => {
          const [type, minutes, seconds] = pace.split(':');
          acc[type] = `${minutes}:${seconds}`;
          return acc;
        }, {} as Record<string, string>);
      }
    }
    return {};
  }, [trainingPaces]);

  useEffect(() => {
    const ws = new WebSocket(
      `${process.env.REACT_APP_GENERATE_TRAINING_PLAN_API_BASE_URL}`
    );
// goal, duration, frequency, targetTime, isKm, trainingDays, trainingPaces, runningLevel
    ws.onopen = () => {
      setIsLoading(true);
      console.log('WebSocket connection established runningLevel', trainingPaces?.runningLevel);
      ws.send(JSON.stringify({
        "action": "message",
        "goal": goal,
        "duration": duration,
        "targetTime": targetTime,
        "isKm": isKm,
        "trainingDays": trainingDays,
        "runningLevel": trainingPaces?.runningLevel || 'beginner'
      }));
    };

    ws.onmessage = (event) => {
      try {
        const data = JSON.parse(event.data);
        if (data.message) {
          const workouts = data.message.split('\n').filter(Boolean);
          console.log('workouts', workouts);

          setTrainingPlan(prevPlan => {
            const newPlan = [...prevPlan];

            workouts.forEach((weekWorkouts: string) => {
              const [weekNum, ...dailyWorkouts] = weekWorkouts.split(',');
              const weekNumber = parseInt(weekNum);

              // Skip if weekNumber is NaN
              if (isNaN(weekNumber)) return;

              // Remove existing workouts for this week if any
              const filteredPlan = newPlan.filter(day => day.week !== weekNumber);

              // Create an array of non-empty workouts
              const nonEmptyWorkouts = dailyWorkouts.filter(workout => workout.trim());
              
              // Map the workouts to the selected training days
              const weekWorkoutDays = trainingDays.map((day, index) => ({
                week: weekNumber,
                day: day,
                workout: nonEmptyWorkouts[index] ? nonEmptyWorkouts[index].replace(/"/g, '') : 'Rest'
              }));

              // Only add valid week numbers
              if (weekNumber > 0) {
                newPlan.push(...weekWorkoutDays);
              }
            });

            // Filter out any remaining entries with null or NaN weeks
            return newPlan.filter(day => day.week && !isNaN(day.week));
          });
        }
      } catch (error) {
        console.error('Error parsing WebSocket message:', error);
      }
    };

    ws.onclose = (event) => {
      setIsLoading(false);
      if (event.wasClean) {
        console.log(`WebSocket connection closed cleanly, code=${event.code}, reason=${event.reason}`);
      } else {
        console.warn('WebSocket connection abruptly closed');
      }
    };

    ws.onerror = (error) => {
      console.error('WebSocket error:', error);
    };

    return () => {
      console.log('WebSocket connection closed');
      ws.close();
    };
  }, [goal, duration]);

  const getWorkoutForDay = (day: string, week: number): string => {
    console.log('trainingPlan', trainingPlan);
    const trainingDay = trainingPlan.find(td => td.day === day && td.week === week);
    return trainingDay ? trainingDay.workout : 'Rest';
  };

  // Calculate number of weeks from duration
  const numberOfWeeks = parseInt(duration);

  const handleSave = async () => {
    setShowModal(true);
  };

  const handleConfirmSave = async () => {
    setIsSaving(true);
    try {
      const trainingPlanData = {
        userId: user?.username,
        trainingPlan,
        planName,
        duration,
        goal,
        targetTime,
        isKm,
        trainingDays,
        trainingPaces: trainingPaces.trainingPaces,
        runningLevel: trainingPaces.runningLevel,
      };

      const saveTrainingPlanResponse = await axios.post(
        `${process.env.REACT_APP_TRAINING_PLANS_API_BASE_URL}/save-training-plan`,
        trainingPlanData,
      );

      if (saveTrainingPlanResponse.data.planId) {
        setShowModal(false);
        navigate(`/view-training-plan/${saveTrainingPlanResponse.data.planId}`);
      }
    } catch (error) {
      console.error('Error saving training plan:', error);
      // Optionally add error handling UI here
    } finally {
      setIsSaving(false);
    }
  };

  const scrollToRunTypes = () => {
    runTypesRef.current?.scrollIntoView({ behavior: 'smooth' });
  };

  return (
    <Container>
      <Box mt={32} mb={32}>
        <Typography>Your Training Plan</Typography>
        
        <Box display="flex" flexDirection="column" alignItems="center" mb={24}>
          <p style={{ textAlign: 'center', maxWidth: '600px', marginBottom: '16px' }}>
            To run a {targetTime} {goal} the following paces are recommended. More information about the types of workouts can be found{' '}
            <LinkText onClick={scrollToRunTypes}>
              here.
            </LinkText>
          </p>
        </Box>

        <Box display="flex" justifyContent="center" mb={24}>
          <PacesTable>
            <thead>
              <tr>
                <Th>Training Type</Th>
                <Th>Pace (min/{isKm ? 'km' : 'mile'})</Th>
              </tr>
            </thead>
            <tbody>
              {Object.entries(parsedPaces).map(([type, pace]) => (
                <tr key={type}>
                  <Td style={{ textTransform: 'capitalize' }}>{type}</Td>
                  <Td>{pace}</Td>
                </tr>
              ))}
            </tbody>
          </PacesTable>
        </Box>

        {isLoading && (
          <>
            <LoadingSpinner>
              <div className="spinner" />
            </LoadingSpinner>
            <Box display="flex" justifyContent="center">
              <p>Generating training plan...</p>
            </Box>
          </>
        )}
        
        <h2>{targetTime} {goal} Training Plan</h2>
        <Table>
          <thead>
            <tr>
              <Th>Week</Th>
              <Th>Monday</Th>
              <Th>Tuesday</Th>
              <Th>Wednesday</Th>
              <Th>Thursday</Th>
              <Th>Friday</Th>
              <Th>Saturday</Th>
              <Th>Sunday</Th>
            </tr>
          </thead>
          <tbody>
            {[...Array(numberOfWeeks)].map((_, weekIndex) => (
              <tr key={weekIndex}>
                <Td>Week {weekIndex + 1}</Td>
                {['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'].map((day) => (
                  <Td key={day}>{getWorkoutForDay(day, weekIndex + 1)}</Td>
                ))}
              </tr>
            ))}
          </tbody>
        </Table>
        <br />
        <ButtonContainer display="flex" justifyContent="center" mb={24}>
          <SaveButton disabled={isSaving || isLoading} onClick={handleSave}>Save Training Plan</SaveButton>
        </ButtonContainer>
      </Box>
        
    <RunTypes isLoadingScreen ref={runTypesRef} id="run-types" />
      
      {showModal && (
        <Modal>
          <ModalContent>
            <Typography>Name Your Training Plan</Typography>
            <Input
              type="text"
              value={planName}
              onChange={(e) => setPlanName(e.target.value)}
              placeholder="Enter plan name"
              onKeyDown={(e) => {
                if (e.key === 'Enter' && !isSaving && planName.trim()) {
                  handleConfirmSave();
                }
              }}
            />
            <ButtonContainer>
              <SaveButton onClick={handleConfirmSave} disabled={isSaving}>
                {isSaving ? (
                  <span className="spinner-border spinner-border-sm me-2" role="status" aria-hidden="true" />
                ) : null}
                {isSaving ? 'Saving...' : 'Save'}
              </SaveButton>
              <ViewButton onClick={() => setShowModal(false)} disabled={isSaving}>
                Cancel
              </ViewButton>
            </ButtonContainer>
          </ModalContent>
        </Modal>
      )}
    </Container>
  );
};

export default ViewTrainingPlan;
