import React, { useState, useRef, useEffect } from 'react';
import styled from '@emotion/styled';
import { Link, useNavigate, useParams } from 'react-router-dom';
import Helmet from 'react-helmet'; // For managing metadata
import axios from 'axios';
import { FaSearch, FaChevronDown } from 'react-icons/fa';

const Container = styled.div`
  max-width: 900px;
  margin: 0 auto;
  padding: 20px;
  background-color: #f0f4f8;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
`;

const LoadingSpinner = styled.div`
  width: 40px;
  height: 40px;
  border: 3px solid #f3f3f3;
  border-top: 3px solid #1976d2;
  border-radius: 50%;
  animation: spin 1s linear infinite;

  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }
`;

const LoadingContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 200px;  // Adjust this value based on your needs
`;

const Header = styled.h1`
  text-align: center;
  color: #264653;
  font-size: 2.5rem;
  margin-bottom: 30px;
  font-family: 'Roboto', sans-serif;
  font-weight: bold;
`;

const BlogGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  gap: 20px;

  @media (min-width: 600px) {
    grid-template-columns: repeat(2, 1fr);
  }

  @media (min-width: 900px) {
    grid-template-columns: repeat(3, 1fr);
  }
`;

const BlogItem = styled.div`
  background-color: #ffffff;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  transition: box-shadow 0.3s ease;
  overflow: hidden;

  &:hover {
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  }
`;

const BlogItemLink = styled(Link)`
  text-decoration: none;
  color: inherit;
  display: block;
  height: 100%;
`;

const BlogContent = styled.div`
  padding: 20px;
`;

const BlogTitle = styled.h2`
  font-size: 1.4rem;
  color: #264653;
  margin-bottom: 10px;
  font-family: 'Roboto', sans-serif;
`;

const ReadMoreLink = styled(Link)`
  color: #2a9d8f;
  text-decoration: none;
  font-weight: bold;
  &:hover {
    text-decoration: underline;
  }
`;

const BlogImage = styled.img`
  width: 100%;
  height: 200px;
  object-fit: cover;
  border-radius: 8px 8px 0 0;
`;

const SecondaryNav = styled.nav`
  background-color: #e9ecef;
  padding: 10px 20px;
  margin-bottom: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const TopicContainer = styled.div`
  display: flex;
  align-items: center;
  flex-grow: 1;
`;

const TopicList = styled.ul`
  list-style: none;
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  margin: 0;
  padding: 0;
`;

const TopicItem = styled.li`
  margin: 0;
`;

const TopicButton = styled.button<{ active: boolean }>`
  background-color: ${props => props.active ? '#2a9d8f' : 'transparent'};
  color: ${props => props.active ? '#ffffff' : '#264653'};
  border: 1px solid #2a9d8f;
  border-radius: 20px;
  padding: 5px 10px;
  cursor: pointer;
  transition: all 0.3s ease;

  &:hover {
    background-color: #2a9d8f;
    color: #ffffff;
  }
`;

const SearchContainer = styled.div`
  position: relative;
  margin-left: 20px;
  min-width: 40px; // Ensure a minimum width for the search icon
`;

const SearchButton = styled.button`
  background: none;
  border: none;
  cursor: pointer;
  font-size: 1.2rem;
  color: #264653;
`;

const SearchInput = styled.input`
  position: absolute;
  right: 100%;
  top: 50%;
  transform: translateY(-50%);
  width: 0;
  padding: 5px;
  border: 1px solid #ccc;
  border-radius: 5px;
  font-size: 1rem;
  opacity: 0;
  transition: width 0.3s ease, opacity 0.3s ease;

  &.active {
    width: 200px;
    opacity: 1;
  }
`;

const AllTopicButton = styled(TopicButton)`
  margin-right: 10px;
`;

const capitalizeWords = (str: string) => {
  return str.split(' ').map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ');
};

const NoResultsContainer = styled.div`
  text-align: center;
  padding: 40px;
  background-color: #ffffff;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
`;

const NoResultsMessage = styled.h2`
  color: #264653;
  font-size: 1.5rem;
  margin-bottom: 20px;
`;

const NoResultsSuggestion = styled.p`
  color: #555555;
  font-size: 1rem;
`;

const BlogPage: React.FC = () => {
  const [activeTopic, setActiveTopic] = useState('All');
  const [searchQuery, setSearchQuery] = useState('');
  const [isSearchActive, setIsSearchActive] = useState(false);
  const [isTopicDropdownOpen, setIsTopicDropdownOpen] = useState(false);
  const dropdownRef = useRef<HTMLDivElement>(null);
  const navigate = useNavigate();
  const { topic } = useParams<{ topic: string }>();
  const [blogPosts, setBlogPosts] = useState<{
    blog_posts: Array<{
      UpdatedAt: string;
      summary: string;
      topic: string;
      slug: string;
      tags: string[];
      CreatedAt: string;
      title: string;
    }>;
    image_urls: string[];
  }>({ blog_posts: [], image_urls: [] });

  const [imageLoadErrors, setImageLoadErrors] = useState<Set<number>>(new Set());

  // Add loading state
  const [isLoading, setIsLoading] = useState(true);

  // Update the fetchBlogPosts function
  const fetchBlogPosts = async (retryCount = 0) => {
    try {
      setIsLoading(true);
      const response = await axios.get(`${process.env.REACT_APP_BLOGS_API_BASE_URL}/getAllBlogs`);
      const parsedData = JSON.parse(response.data.body);
      setBlogPosts(parsedData);
      setImageLoadErrors(new Set());
    } catch (error) {
      console.error('Error fetching blog posts:', error);
      if (retryCount < 3) {
        setTimeout(() => fetchBlogPosts(retryCount + 1), 1000 * (retryCount + 1));
      }
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchBlogPosts();
    
    // Refresh URLs every 45 minutes
    const interval = setInterval(fetchBlogPosts, 45 * 60 * 1000);
    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    if (topic) {
      const validTopics = ['race-guides', 'nutrition', 'running-routes', 'running-training', 'running-injuries', 'parkrun'];
      const formattedTopic = topic.toLowerCase();
      console.log('formattedTopic', formattedTopic);
      const windowPath = window.location.pathname;
      console.log('windowPath', windowPath);
      
      if (!validTopics.includes(formattedTopic)) {
        navigate('/404', { 
          replace: true,
          state: { from: windowPath.includes('blog') || windowPath.includes('blogs') ? 'blogs' : null }
        });
      }
    }
  }, [topic, navigate]);

  useEffect(() => {
    if (topic) {
      setActiveTopic(decodeURIComponent(topic).replace(/-/g, ' '));
    } else {
      setActiveTopic('All');
    }
  }, [topic]);

  const topics = ['Race Guides', 'Nutrition', 'Running Routes', 'Running Training', 'Running Injuries', 'Parkrun'];

  const filteredPosts = (blogPosts?.blog_posts || [])
    .sort((a, b) => new Date(b.UpdatedAt).getTime() - new Date(a.UpdatedAt).getTime())
    .filter(post => {
      if (activeTopic === 'All') return true;
      const normalizedPostTopic = post.topic?.toLowerCase().replace(/-/g, ' ') || '';
      const normalizedActiveTopic = activeTopic.toLowerCase();
      return normalizedPostTopic === normalizedActiveTopic;
    })
    .filter(post => 
      (post.title?.toLowerCase() || '').includes(searchQuery.toLowerCase()) ||
      (post.summary?.toLowerCase() || '').includes(searchQuery.toLowerCase())
    );

  console.log('filteredPosts', filteredPosts);

  const handleSearchClick = () => {
    setIsSearchActive(!isSearchActive);
    setIsTopicDropdownOpen(false);
    
    // Focus on the search input after a short delay
    setTimeout(() => {
      if (!isSearchActive && searchInputRef.current) {
        searchInputRef.current.focus();
      }
    }, 100);
  };

  const handleTopicClick = (topic: string) => {
    if (topic === 'All') {
      navigate('/blogs');
    } else {
      navigate(`/blogs/${encodeURIComponent(topic.toLowerCase().replace(/\s+/g, '-'))}`);
    }
  };

  const handleMoreTopicsClick = () => {
    setIsSearchActive(false);
    setSearchQuery('');
  };

  const pageTitle = activeTopic === 'All' ? 'Running Blog' : `${capitalizeWords(activeTopic)}`;

  const searchInputRef = useRef<HTMLInputElement>(null);

  const getSearchPlaceholder = () => {
    if (activeTopic === 'All') {
      return "Search all blog posts...";
    } else {
      return `Search ${activeTopic.toLowerCase()} posts...`;
    }
  };

  return (
    <Container>
      <Helmet>
        <title>{pageTitle}</title>
        <meta name="description" content={`Explore our blog for expert tips on ${activeTopic === 'All' ? 'marathon training, running energy gels, and essential advice for new runners' : activeTopic.toLowerCase()}. Stay informed and motivated with our latest posts.`} />
        <meta name="keywords" content={`${activeTopic}, running tips, running blog`} />
        <meta property="og:title" content={pageTitle} />
        <meta property="og:description" content={`Explore our blog for expert tips on ${activeTopic === 'All' ? 'training for your next race, running nutrition, and essential advice for new runners' : activeTopic.toLowerCase()}. Stay informed and motivated with our latest posts.`} />
        <meta property="og:type" content="website" />
      </Helmet>
      <Header>{pageTitle}</Header>
      <SecondaryNav>
        <TopicContainer>
          <AllTopicButton 
            active={activeTopic === 'All'}
            onClick={() => handleTopicClick('All')}
          >
            All
          </AllTopicButton>
          {!isSearchActive && (
            <TopicList>
              {topics.map(topic => (
                <TopicItem key={topic}>
                  <TopicButton 
                    active={activeTopic === topic}
                    onClick={() => handleTopicClick(topic)}
                  >
                    {topic}
                  </TopicButton>
                </TopicItem>
              ))}
            </TopicList>
          )}
          {isSearchActive && (
            <TopicButton 
              active={false}
              onClick={handleMoreTopicsClick}
            >
              More Topics <FaChevronDown />
            </TopicButton>
          )}
        </TopicContainer>
        <SearchContainer>
          <SearchButton onClick={handleSearchClick}>
            <FaSearch />
          </SearchButton>
          <SearchInput
            ref={searchInputRef}
            type="text"
            placeholder={getSearchPlaceholder()}
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
            className={isSearchActive ? 'active' : ''}
          />
        </SearchContainer>
      </SecondaryNav>
      {isLoading ? (
        <LoadingContainer>
          <LoadingSpinner />
        </LoadingContainer>
      ) : filteredPosts.length > 0 ? (
        <BlogGrid>
          {filteredPosts.map((post, index) => (
            <BlogItem key={post.CreatedAt}>
              <BlogItemLink 
                to={`/blog/${encodeURIComponent((post.topic ?? 'uncategorized').toLowerCase().replace(/\s+/g, '-'))}/${post.slug}`}
                state={{ 
                  imageUrl: (blogPosts?.image_urls || []).find(url => url.includes(post.slug)) || 
                            `https://public-blog-images-mlp.s3.eu-north-1.amazonaws.com/${post.slug}.jpg`
                }}
              >
                <BlogImage 
                  src={(blogPosts?.image_urls || []).find(url => url.includes(post.slug)) || 
                       `https://public-blog-images-mlp.s3.eu-north-1.amazonaws.com/${post.slug}.jpg`}
                  alt={post.title}
                />
                <BlogContent>
                  <BlogTitle>{post.title}</BlogTitle>
                  <ReadMoreLink to={`/blog/${post.topic?.toLowerCase().replace(/\s+/g, '-') || 'uncategorized'}/${post.slug}`}>Read More</ReadMoreLink>
                </BlogContent>
              </BlogItemLink>
            </BlogItem>
          ))}
        </BlogGrid>
      ) : (
        <NoResultsContainer>
          <NoResultsMessage>No results found</NoResultsMessage>
          <NoResultsSuggestion>
            Try adjusting your search or filter to find more posts.
          </NoResultsSuggestion>
        </NoResultsContainer>
      )}
    </Container>
  );
};

export default BlogPage;
