// Dynamically create awsconfig based on environment variables

const awsmobile = {
    "aws_project_region": process.env.REACT_APP_AWS_REGION,
    "aws_cognito_identity_pool_id": process.env.REACT_APP_COGNITO_IDENTITY_POOL_ID,
    "aws_cognito_region": process.env.REACT_APP_COGNITO_REGION,
    "aws_user_pools_id": process.env.REACT_APP_USER_POOLS_ID,
    "aws_user_pools_web_client_id": process.env.REACT_APP_USER_POOLS_WEB_CLIENT_ID,
    "oauth": {
        "domain": 'airunningtraining.auth.eu-north-1.amazoncognito.com',
        "scope": ['email', 'openid', 'profile'],
        "redirectSignIn": 'https://airunningtraining.com/account',
        "redirectSignOut": 'https://airunningtraining.com/login',
        "responseType": 'code'
    },
    "aws_cognito_username_attributes": [],
    "aws_cognito_social_providers": [
        "Google"
    ],
    "aws_cognito_signup_attributes": [
        "EMAIL"
    ],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [
        "SMS"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": []
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ],
    "aws_cloud_logic_custom": [
        {
            "name": "fetchApiKeyApi",
            "endpoint": process.env.REACT_APP_API_ENDPOINT,
            "region": process.env.REACT_APP_AWS_REGION
        }
    ],
    "Auth": {
        "identityPoolId": process.env.REACT_APP_COGNITO_IDENTITY_POOL_ID,
        "region": process.env.REACT_APP_AWS_REGION,
        "userPoolId": process.env.REACT_APP_USER_POOLS_ID,
        "userPoolWebClientId": process.env.REACT_APP_USER_POOLS_WEB_CLIENT_ID,
        "Cognito": {
            "oauth": {
                "domain": 'airunningtraining.auth.eu-north-1.amazoncognito.com',
                "scope": ['email', 'openid', 'profile'],
                "redirectSignIn": 'https://airunningtraining.com/account',
                "redirectSignOut": 'https://airunningtraining.com/login',
                "responseType": 'code'
            }
        }
    },
    "API": {
        "endpoints": [
            {
                "name": "fetchApiKeyApi",
                "endpoint": process.env.REACT_APP_API_ENDPOINT
            }
        ]
    }
};

export default awsmobile;
